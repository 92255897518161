import { useIntl } from 'react-intl';
import styles from './AffiliateStatistics.module.scss';
import { translate } from 'src/utility/messageTranslator/translate';
import { User } from '../../../../../domain/User';
import { StoreState } from '../../../../../config/StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as userService from '../../../../../store/user/service';
import { connect } from 'react-redux';
import { useEffect } from 'react';
import { FormattedPrice } from '../../../../../common/FormattedPrice/FormattedPrice';

export type Props = {
  invitedUsersCount: number;
  currentUser: User | null;
  invitedUsersSalesCount: number;
  onFetchInvitedUsersSalesCount: () => void;
};

export const AffiliateStatistics = ({
  invitedUsersCount,
  currentUser,
  onFetchInvitedUsersSalesCount,
  invitedUsersSalesCount,
}: Props) => {
  const intl = useIntl();
  useEffect(() => {
    onFetchInvitedUsersSalesCount();
  }, []);

  return (
    <div className={styles.affiliateStatistics}>
      <div className={styles.affiliateStatisticsWrapper}>
        <div className={styles.statsticsItem}>
          <h3 className={styles.statsticsTitle}>{invitedUsersCount}</h3>
          <p className={styles.statstics}>
            {translate(intl, 'AFFILIATE_STATISTICS.REFERRALS')}
          </p>
        </div>
        <div className={styles.statsticsItem}>
          <div className={styles.statsticsTitleWrapper}>
            <h3 className={styles.statsticsTitle}>
              {
                <FormattedPrice
                  price={currentUser?.profile?.affiliateEarningsEur || 0}
                />
              }
            </h3>
          </div>
          <p className={styles.statstics}>
            {translate(intl, 'AFFILIATE_STATISTICS.AFFILIATE_EARNINGS')}
          </p>
        </div>
        <div className={styles.statsticsItem}>
          <h3 className={styles.statsticsTitle}>{invitedUsersSalesCount}</h3>
          <p className={styles.statstics}>
            {translate(intl, 'AFFILIATE_STATISTICS.SALES')}
          </p>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  invitedUsersSalesCount: state.user.invitedUsersSalesCount,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchInvitedUsersSalesCount: () =>
    dispatch(userService.fetchInvitedUsersSalesCount()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(AffiliateStatistics);
