import React, {
  ChangeEvent,
  ReactNode,
  useEffect,
  useMemo,
  useState,
} from 'react';
import {
  Autocomplete as MuiAutocomplete,
  Checkbox,
  Chip,
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Switch,
  TextField as MuiTextField,
  RadioGroup,
  Radio,
  ListItemSecondaryAction,
  ListItemText,
  ListItem,
  IconButton,
  Tooltip,
  SvgIconTypeMap,
  InputAdornment,
  Popper,
} from '@mui/material';
import styles from './TextField.module.scss';
import cx from 'classnames';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { StaticDatePicker } from '@mui/x-date-pickers/StaticDatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import moment from 'moment';
import Alert from '../Alert/Alert';
import useDelayedSearch, {
  UseDelayedSearch,
} from '../../hooks/useDelayedSearch/useDelayedSearch';
import { Asset } from '../../domain/Asset';
import Button from '../Button/Button';
import { InputProps as StandardInputProps } from '@mui/material/Input/Input';
import { translate } from '../../utility/messageTranslator/translate';
import { useIntl } from 'react-intl';
import { Editor } from '@tinymce/tinymce-react';
import FileInput from '../FileInput/FileInput';
import { HttpError } from '../../config/Axios/axios-instance';
import * as assetService from '../../store/asset/service';
import { enGB, lt } from 'date-fns/esm/locale';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { Locale } from '../../domain/Translation';
import { SignUpSectionType } from '../../pages/Public/SignUpPage/SignUpPage';
import { TypeAnimation } from 'react-type-animation';
import ProfileImageInput from '../ProfileImageInput/ProfileImageInput';
import { TablePriorityUpdate } from '../Table/Table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { IconProp } from '@fortawesome/fontawesome-svg-core';
import infoIcon from '../../assets/icons/info.svg';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { ReactSVG } from 'react-svg';
import bricks from '../../assets/icons/bricks.svg';

export type AutocompleteOptionProps = {
  label: string;
  value: string;
};

type RadioInput = {
  value?: number;
  label?: string;
  additionalInfo?: string;
  icon?: string;
  valueLabel?: string;
};

export type RadioProps = {
  value: string;
  radioInputs: RadioInput[];
};

type Props = {
  selectedLocale: Locale;
  onChange?: (event: ChangeEvent<any>) => void;
  onFileChange?: (event: ChangeEvent<any>) => void;
  onFileUpload?: (event: ChangeEvent<any>) => void;
  onCheckboxChange?: (name: string, value: string) => void;
  onInputValueChange?: (name: string, value: string) => void;
  onTimeChange?: (name: string, value: string) => void;
  onBlur?: (event: ChangeEvent<any>, freeSolo?: boolean) => void;
  onInputBlur?: (name: string) => void;
  onImageDelete?: (name: string, value: string | File) => void;
  onSetValidationErrors?: (error: HttpError) => void;
  onOptionDelete?: (option: AutocompleteOptionProps) => void;
  value?: string | string[] | File | File[];
  label?: string | ReactNode;
  errors?: Array<string>;
  name: string;
  type?: string;
  placeholder?: string;
  inputProps?: Partial<StandardInputProps>;
  className?: string;
  options?: Array<{ value: string; label: string }>;
  labelPlacement?: 'end' | 'start' | 'top' | 'bottom';
  variant?: 'filled' | 'outlined' | 'standard';
  isOutlined?: boolean;
  disabled?: boolean;
  helperText?: string;
  clearable?: boolean;
  multiple?: boolean;
  disableCloseOnSelect?: boolean;
  disableClearable?: boolean;
  onSelectChange?: (
    value: string | string[],
    name: string,
    freeSolo?: boolean,
  ) => void;
  isLoading?: boolean;
  onSearch?: (searchValue: string) => void;
  moreLabel?: string;
  asset?: Asset | Asset[] | null;
  creatable?: boolean;
  onCreate?: () => void;
  autoFocus?: boolean;
  isSmall?: boolean;
  maxFileCount?: number;
  onFileDropped?: (name: string, files: File[]) => void;
  freeSolo?: boolean;
  limit?: number;
  onShowMoreClick?: () => void;
  inputLabelProps?: {
    shrink?: boolean;
    sx?: {
      marginTop?: string;
    };
  };
  richTextProps?: {
    toolbar?: string;
    menubar?: boolean;
    height?: number;
    paste_data_images?: boolean;
  };
  maxLimit?: number;
  forgotPassword?: {
    label: string;
    section: SignUpSectionType;
  };
  onChangeSection?: (state: SignUpSectionType) => void;
  radioProps?: RadioProps;
  onRadioChange?: (name: string, value: string) => void;
  hasTypeAnimation?: boolean;
  isAssessmentContainerVisible?: boolean;
  onDragDrop?: (updatedPriorities: TablePriorityUpdate[]) => void;
  firstImageLabelTitle?: string;
  headerTitle?: string;
  allowedExtensionsLabel?: string;
  fileSizeLabel?: string;
  isNotImage?: boolean;
  checkedIcon?: string;
  uncheckedIcon?: string;
  explanationLabel?: string | ReactNode;
  required?: boolean;
  selectIcon?: OverridableComponent<SvgIconTypeMap<{}, 'svg'>>;
  addBricks?: boolean;
  convertedValue?: string;
  popperClassName?: string;
  useOnChangeInAutocompleteTextField?: boolean;
  onFocus?: () => void;
  clearField?: boolean;
  onClearField?: (event: React.MouseEvent) => void;
  hideValidationMessage?: boolean;
  isPublicAsset?: boolean;
};

const TextField = ({
  selectedLocale,
  onChange,
  onBlur,
  value,
  label,
  errors,
  name,
  type,
  placeholder,
  inputProps,
  className,
  onFileChange,
  onCheckboxChange,
  options,
  labelPlacement,
  onTimeChange,
  disabled,
  helperText,
  clearable,
  multiple,
  disableCloseOnSelect,
  disableClearable,
  onSelectChange,
  isLoading = false,
  onSearch,
  moreLabel,
  asset,
  creatable,
  onCreate,
  autoFocus,
  variant = 'outlined',
  isSmall,
  onInputBlur,
  onInputValueChange,
  maxFileCount,
  onImageDelete,
  onSetValidationErrors,
  onOptionDelete,
  onFileDropped,
  freeSolo,
  limit,
  onShowMoreClick,
  richTextProps,
  inputLabelProps,
  maxLimit,
  forgotPassword,
  onChangeSection,
  radioProps,
  onRadioChange,
  hasTypeAnimation,
  isAssessmentContainerVisible,
  onDragDrop,
  firstImageLabelTitle,
  headerTitle,
  allowedExtensionsLabel,
  fileSizeLabel,
  isNotImage,
  checkedIcon,
  uncheckedIcon,
  onFileUpload,
  explanationLabel,
  required,
  selectIcon,
  addBricks,
  convertedValue,
  popperClassName,
  useOnChangeInAutocompleteTextField,
  onFocus,
  clearField,
  onClearField,
  hideValidationMessage,
  isPublicAsset,
}: Props) => {
  const [autocompleteInput, setAutocompleteInput] = useState<string>('');
  const hiddenInputRef = React.useRef<HTMLInputElement>(null);

  const handleClick = () => {
    if (hiddenInputRef.current) {
      hiddenInputRef.current.click();
    }
  };

  const intl = useIntl();

  const showErrors = useMemo(() => variant !== 'filled', [variant]);

  const adapterLocale = useMemo(() => {
    if (selectedLocale === 'lt') {
      return lt;
    }

    return enGB;
  }, [selectedLocale]);

  const { setSearchValue }: UseDelayedSearch = useDelayedSearch(
    !!onSearch ? onSearch : () => {},
  );

  useEffect(() => {
    if (type === 'autocomplete') {
      setSearchValue(autocompleteInput);
    }
  }, [autocompleteInput]);

  useEffect(() => {
    if (type === 'richtext') {
      document.addEventListener('focusin', handleRichTextBoxFocusIn);
    }
  }, []);

  useEffect(() => {
    return () =>
      document.removeEventListener('focusin', handleRichTextBoxFocusIn);
  }, []);

  const handleRichTextBoxFocusIn = (event: FocusEvent) => {
    if (
      event.target &&
      // @ts-ignore
      event.target.closest(
        '.tox-tinymce-aux, .moxman-window, .tam-assetmanager-root',
      ) !== null
    ) {
      event.stopImmediatePropagation();
    }
  };

  if (type === 'autocomplete') {
    const getSelectionValue = () => {
      if (Array.isArray(value)) {
        return options?.filter((option) =>
          value.toString().includes(option.value),
        );
      }

      if (multiple) {
        const values = value?.toString().split(',');
        return options?.filter(
          (option) =>
            !!values?.find(
              (singleValue) =>
                singleValue?.toString() === option.value?.toString(),
            ),
        );
      }

      return options?.find(
        (option) => option.value?.toString() === value?.toString(),
      );
    };

    const getOptions = () => {
      if (!options) {
        return [];
      }

      if (moreLabel) {
        return [...options, { label: moreLabel, value: '' }];
      }

      return options;
    };

    const renderTags = (value: any[], getTagProps: Function) => {
      if (!limit) {
        return;
      }

      const numTags = value.length;

      return (
        <>
          {value.slice(0, limit).map((option, index) => (
            <Chip
              {...getTagProps({ index })}
              key={index}
              label={option.label}
            />
          ))}

          {numTags > limit && (
            <Chip
              label={translate(intl, 'TEXT_FIELD.SHOW_MORE').replace(
                ':count',
                (numTags - limit).toString(),
              )}
              onClick={onShowMoreClick}
            />
          )}
        </>
      );
    };

    const getOptionDisabled = () => {
      const value = getSelectionValue();

      return Array.isArray(value) && value.length === maxLimit;
    };

    const getFreeSoloValue = () => {
      if (!maxLimit) {
        return freeSolo;
      }

      const value = getSelectionValue();

      if (!Array.isArray(value)) {
        return;
      }

      return !(value.length === maxLimit);
    };

    return (
      <MuiAutocomplete
        id={name}
        freeSolo={getFreeSoloValue()}
        multiple={multiple}
        options={getOptions()}
        getOptionLabel={(option: any) => option.label}
        value={getSelectionValue() ?? null}
        disableCloseOnSelect={disableCloseOnSelect}
        disabled={disabled}
        disableClearable={disableClearable}
        getOptionDisabled={maxLimit ? getOptionDisabled : undefined}
        inputValue={multiple ? autocompleteInput : undefined}
        renderTags={limit ? renderTags : undefined}
        renderOption={
          onOptionDelete
            ? (props, option, state) => (
                <ListItem {...props}>
                  <ListItemText primary={option.label} />
                  <ListItemSecondaryAction>
                    <IconButton
                      edge="end"
                      aria-label="delete"
                      onClick={() => onOptionDelete?.(option)}
                    >
                      <FontAwesomeIcon
                        icon={faTrash as IconProp}
                        fixedWidth
                        size="xs"
                      />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              )
            : undefined
        }
        PopperComponent={(props) => (
          <Popper {...props} className={cx(styles.popper, popperClassName)} />
        )}
        onChange={(_: any, values: any) => {
          setAutocompleteInput('');
          if (Array.isArray(values)) {
            return onSelectChange?.(
              (values as AutocompleteOptionProps[] | string[]).map((value) => {
                if (typeof value === 'string') {
                  return value;
                }

                return value.value;
              }),
              name,
              freeSolo,
            );
          }

          if (typeof values === 'object') {
            return onSelectChange?.(values?.value || '', name, freeSolo);
          }

          onSelectChange?.(values?.valueOf() || '', name, freeSolo);
        }}
        onBlur={(e) => {
          onBlur?.(e, freeSolo);

          if (freeSolo) {
            setAutocompleteInput('');
          }
        }}
        loading={isLoading}
        renderInput={(params: any) => (
          <MuiTextField
            {...params}
            label={label}
            placeholder={placeholder}
            helperText={
              showErrors && errors && errors.length > 0 ? errors[0] : ''
            }
            error={errors && errors.length > 0}
            name={name}
            className={cx(styles.textField, styles.textField, className)}
            variant={variant as any}
            size={isSmall ? 'small' : 'medium'}
            onFocus={onFocus}
            onChange={(event) => {
              setAutocompleteInput(event.target.value);
              useOnChangeInAutocompleteTextField && onChange?.(event);
            }}
            InputProps={{
              ...params.InputProps,
              disableUnderline: variant === 'filled' && !errors?.length,
              endAdornment: (
                <React.Fragment>
                  {isLoading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : null}
                  {creatable && (
                    <Button buttonVariant="text" onClick={() => onCreate?.()}>
                      {translate(intl, 'INPUTS.CREATE')}
                    </Button>
                  )}
                  {explanationLabel && (
                    <Tooltip title={explanationLabel}>
                      <img src={infoIcon} className={styles.infoIcon} />
                    </Tooltip>
                  )}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
    );
  }

  if (type === 'file') {
    return (
      <FileInput
        value={value as File | File[] | string | string[] | undefined}
        name={name}
        helperText={helperText}
        label={label}
        onFileChange={onFileChange}
        maxFileCount={maxFileCount}
        multiple={multiple}
        errors={errors}
        asset={asset}
        onImageDelete={onImageDelete}
        onSetValidationErrors={onSetValidationErrors}
        onFileDropped={onFileDropped}
        onDragDrop={onDragDrop}
        firstImageLabelTitle={firstImageLabelTitle}
        headerTitle={headerTitle}
        allowedExtensionsLabel={allowedExtensionsLabel}
        fileSizeLabel={fileSizeLabel}
        isNotImage={isNotImage}
        explanationLabel={explanationLabel}
        required={required}
        clearField={clearField}
        onClearField={onClearField}
      />
    );
  }

  if (type === 'profileImage') {
    return (
      <ProfileImageInput
        value={value}
        name={name}
        onFileChange={onFileChange}
        errors={errors}
        hideValidationMessage={hideValidationMessage}
      />
    );
  }

  if (type === 'upload') {
    return (
      <Button className={styles.upload} onClick={handleClick}>
        {label}
        <input type="file" onChange={onFileUpload} ref={hiddenInputRef} />
      </Button>
    );
  }

  if (type === 'currency') {
    return (
      <>
        {helperText && <Alert variant="warning">{helperText}</Alert>}
        <MuiTextField
          onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
          value={value}
          onChange={onChange}
          onBlur={onBlur}
          label={label}
          error={errors && errors.length > 0}
          variant={variant as any}
          name={name}
          helperText={
            showErrors && errors && errors.length > 0 ? errors[0] : ''
          }
          className={cx(styles.textField, styles.currencyInput, className)}
          type="number"
          disabled={disabled}
          placeholder={placeholder}
          inputProps={{ inputMode: 'numeric', pattern: '[0-9]*' }}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
            startAdornment: addBricks ? (
              <InputAdornment position="start" className={styles.bricks}>
                <ReactSVG src={bricks} />
              </InputAdornment>
            ) : undefined,
            endAdornment: (
              <>
                {explanationLabel && (
                  <Tooltip title={explanationLabel}>
                    <img src={infoIcon} className={styles.infoIcon} />
                  </Tooltip>
                )}
                {convertedValue && (
                  <div
                    className={styles.convertedPrice}
                  >{`€${convertedValue}`}</div>
                )}
              </>
            ),
          }}
          size={isSmall ? 'small' : 'medium'}
        />
      </>
    );
  }

  if (type === 'time') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <TimePicker
          ampm={false}
          value={
            typeof value === 'string' ? moment(value, 'HH:mm').toDate() : ''
          }
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(name, moment(newValue?.toString()).format('HH:mm'));
          }}
          label={label}
          disabled={disabled}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, className)}
              onBlur={onBlur}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'staticdate') {
    return (
      <LocalizationProvider
        dateAdapter={AdapterDateFns}
        adapterLocale={adapterLocale}
      >
        <StaticDatePicker
          displayStaticWrapperAs="desktop"
          openTo="day"
          minDate={moment().startOf('year').format('YYYY-MM-DD')}
          maxDate={moment().add(1, 'month').format('YYYY-MM-DD')}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          className={styles.staticDateContainer}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'datetime') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          views={['year', 'month', 'day', 'hours', 'minutes']}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD HH:mm')
                  : '',
              );
          }}
          mask="____-__-__ __:__"
          disabled={disabled}
          inputFormat="yyyy-MM-dd HH:mm"
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'date') {
    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <DateTimePicker
          views={['year', 'month', 'day']}
          label={label}
          value={value}
          onChange={(newValue: any) => {
            onTimeChange &&
              onTimeChange(
                name,
                newValue
                  ? moment(newValue.toString()).format('YYYY-MM-DD')
                  : '',
              );
          }}
          mask="____-__-__"
          disabled={disabled}
          inputFormat="yyyy-MM-dd"
          InputProps={{
            disableUnderline: variant === 'filled' && !errors?.length,
          }}
          renderInput={(params: any) => (
            <MuiTextField
              {...params}
              className={cx(styles.textField, className)}
              onBlur={onBlur}
              name={name}
              helperText={
                showErrors && errors && errors.length > 0 ? errors[0] : ''
              }
              error={errors && errors.length > 0}
              variant={variant as any}
              size={isSmall ? 'small' : 'medium'}
            />
          )}
        />
      </LocalizationProvider>
    );
  }

  if (type === 'switcher') {
    return (
      <FormControlLabel
        className={cx(styles.switcherContainer, className)}
        labelPlacement={labelPlacement}
        control={
          <Switch
            name={name}
            checked={value === '1'}
            onChange={() =>
              onCheckboxChange &&
              onCheckboxChange(name, value === '0' ? '1' : '0')
            }
          />
        }
        disabled={disabled}
        label={label}
      />
    );
  }

  if (type === 'checkbox' && checkedIcon && uncheckedIcon) {
    return (
      <>
        <div
          className={cx(styles.customCheckboxContainer, className)}
          onChange={() =>
            onCheckboxChange &&
            onCheckboxChange(name, value === '0' ? '1' : '0')
          }
        >
          {value === '1' ? (
            <img
              src={checkedIcon}
              alt="Checked"
              onClick={() => onCheckboxChange && onCheckboxChange(name, '0')}
            />
          ) : (
            <img
              src={uncheckedIcon}
              alt="Unchecked"
              onClick={() => onCheckboxChange && onCheckboxChange(name, '1')}
            />
          )}

          <FormControlLabel
            className={cx(styles.checkboxContainer, className)}
            labelPlacement={labelPlacement}
            sx={{
              '&:hover': {
                backgroundColor: 'transparent',
              },
            }}
            control={
              <Checkbox checked={value === '1'} style={{ display: 'none' }} />
            }
            disabled={disabled}
            label={label}
            style={{ backgroundColor: 'none' }}
          />
        </div>
        {errors && errors.length > 0 && (
          <div className={styles.checkboxError}>{errors[0]}</div>
        )}
      </>
    );
  }

  if (type === 'select') {
    return (
      <MuiTextField
        value={value}
        select
        onChange={onChange}
        onBlur={onBlur}
        label={label}
        error={errors && errors.length > 0}
        variant={variant as any}
        name={name}
        helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
        className={cx(styles.textField, className)}
        type="number"
        InputProps={{
          ...inputProps,
          endAdornment: explanationLabel && (
            <Tooltip title={explanationLabel}>
              <img src={infoIcon} className={styles.infoIcon} />
            </Tooltip>
          ),
        }}
        SelectProps={{
          IconComponent: selectIcon,
          MenuProps: { disableScrollLock: true },
        }}
        placeholder={placeholder}
        required={required}
        size={isSmall ? 'small' : 'medium'}
      >
        {options?.map((option) => (
          <MenuItem key={option.value} value={option.value}>
            {option.label}
          </MenuItem>
        )) ?? []}
      </MuiTextField>
    );
  }

  if (type === 'richtext') {
    return (
      <div
        className={cx(styles.editorContainer, {
          [styles.error]: errors && errors.length,
        })}
      >
        <div className={styles.label}>
          {label}
          {required && <span>*</span>}
          {explanationLabel && (
            <Tooltip title={explanationLabel}>
              <img src={infoIcon} className={styles.infoIcon} />
            </Tooltip>
          )}
        </div>
        <Editor
          apiKey={process.env.REACT_APP_TINYMCE_KEY}
          disabled={disabled}
          value={value?.toString()}
          onEditorChange={(value) =>
            onInputValueChange && onInputValueChange(name, value)
          }
          onBlur={(event) => {
            event.preventDefault();

            onInputBlur && onInputBlur(name);
          }}
          init={{
            automatic_uploads: true,
            file_picker_types: 'image',
            plugins: 'image | link',
            toolbar:
              'undo redo | formatselect | ' +
              'bold italic backcolor image | alignleft aligncenter ' +
              'alignright alignjustify | bullist numlist outdent indent | ' +
              'link | removeformat | help',
            images_upload_handler: async (blobInfo) => {
              return new Promise(async (resolve, reject) => {
                await assetService.createAsset(
                  blobInfo.blob(),
                  resolve,
                  reject,
                  isPublicAsset,
                );
              });
            },
            ...richTextProps,
          }}
        />
        {errors &&
          errors.map((error, index) => (
            <div className={styles.editorError} key={`editor-error-${index}`}>
              {error}
            </div>
          ))}
      </div>
    );
  }

  if (type === 'radio' && radioProps && onRadioChange) {
    return (
      <RadioGroup
        value={value}
        onChange={(event) => onRadioChange(name, event.target.value)}
        name={name}
        row
      >
        {radioProps.radioInputs.map((radioInput) => (
          <FormControlLabel
            key={radioInput.value}
            value={radioInput.value}
            control={<Radio />}
            label={
              hasTypeAnimation && isAssessmentContainerVisible ? (
                <TypeAnimation
                  sequence={[radioInput.label as string]}
                  speed={65}
                  repeat={1}
                  cursor={false}
                />
              ) : (
                radioInput.label
              )
            }
          />
        ))}
      </RadioGroup>
    );
  }

  if (type === 'decorativeRadio' && radioProps) {
    return (
      <div className={styles.decorativeRadio}>
        <RadioGroup
          value={value}
          onChange={(event) =>
            onRadioChange && onRadioChange(name, event.target.value)
          }
          name={name}
          row
        >
          {radioProps.radioInputs.map((radioInput) => (
            <FormControlLabel
              key={radioInput.value}
              value={radioInput.value}
              control={<Radio />}
              label={
                <div className={styles.label}>
                  <div className={styles.leftContainer}>
                    {radioInput.icon && (
                      <div className={styles.icon}>
                        <ReactSVG src={radioInput.icon} />
                      </div>
                    )}
                    <div className={styles.info}>
                      <div className={styles.radioInputLabel}>
                        {radioInput?.label}
                      </div>
                      <div className={styles.additionalInfo}>
                        {radioInput?.additionalInfo}
                      </div>
                    </div>
                  </div>
                  <div className={styles.rightContainer}>
                    <div className={styles.valueLabel}>
                      {radioInput?.valueLabel}
                    </div>
                  </div>
                </div>
              }
            />
          ))}
        </RadioGroup>
      </div>
    );
  }

  return (
    <MuiTextField
      onWheel={(e) => e.target instanceof HTMLElement && e.target.blur()}
      value={value}
      onChange={onChange}
      onBlur={onBlur}
      label={label}
      error={errors && errors.length > 0}
      variant={variant as any}
      name={name}
      helperText={showErrors && errors && errors.length > 0 ? errors[0] : ''}
      className={cx(styles.textField, className)}
      type={type}
      placeholder={placeholder}
      InputLabelProps={inputLabelProps}
      InputProps={{
        ...inputProps,
        endAdornment: explanationLabel && (
          <Tooltip title={explanationLabel}>
            <img src={infoIcon} className={styles.infoIcon} />
          </Tooltip>
        ),
      }}
      multiline={type === 'textarea'}
      rows={5}
      disabled={disabled}
      autoFocus={autoFocus}
      size={isSmall ? 'small' : 'medium'}
      required={required}
    />
  );
};

const mapStateToProps = (state: StoreState) => ({
  selectedLocale: state.auth.selectedLocale,
});

export default connect(mapStateToProps)(TextField);
