export const RESET_BRICKS_STATUS_START = 'RESET_BRICKS_STATUS_START';
export const RESET_BRICKS_STATUS_SUCCESS = 'RESET_BRICKS_STATUS_SUCCESS';
export const RESET_BRICKS_STATUS_FAIL = 'RESET_BRICKS_STATUS_FAIL';

export const UPDATE_LOGIN_STATUS_START = 'UPDATE_LOGIN_STATUS_START';
export const UPDATE_LOGIN_STATUS_SUCCESS = 'UPDATE_LOGIN_STATUS_SUCCESS';
export const UPDATE_LOGIN_STATUS_FAIL = 'UPDATE_LOGIN_STATUS_FAIL';

export const RESET_PROFILE_STORE = 'RESET_PROFILE_STORE';

export const LOGOUT = 'LOGOUT';

export type ProfileActionTypes =
  | typeof RESET_BRICKS_STATUS_START
  | typeof RESET_BRICKS_STATUS_SUCCESS
  | typeof RESET_BRICKS_STATUS_FAIL
  | typeof UPDATE_LOGIN_STATUS_START
  | typeof UPDATE_LOGIN_STATUS_SUCCESS
  | typeof UPDATE_LOGIN_STATUS_FAIL
  | typeof RESET_PROFILE_STORE
  | typeof LOGOUT;
