import { useState, useEffect } from 'react';
import { TopBarHighlight } from '../../../domain/TopBarHighlight';
import TopBarHighlightView from '../TopBarHighlightView/TopBarHighlightView';
import styles from './TopBarHighlightCarousel.module.scss';
import cx from 'classnames';

type Props = {
  highlights: TopBarHighlight[];
};

const TopBarHighlightCarousel = ({ highlights }: Props) => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const [fade, setFade] = useState(true);
  const [isPaused, setIsPaused] = useState(false);

  useEffect(() => {
    if (isPaused) {
      return;
    }

    const interval = setInterval(() => {
      setFade(false);

      setTimeout(() => {
        setCurrentIndex((prevIndex) => (prevIndex + 1) % highlights.length);
        setFade(true);
      }, 500);
    }, 5000);

    return () => clearInterval(interval);
  }, [highlights.length, isPaused]);

  return (
    <div
      className={cx(styles.highlightContainer, {
        [styles.fadeIn]: fade,
        [styles.fadeOut]: !fade,
      })}
    >
      <TopBarHighlightView
        topBarHighlight={highlights[currentIndex]}
        onModalOpen={(isOpen) => setIsPaused(isOpen)}
      />
    </div>
  );
};

export default TopBarHighlightCarousel;
