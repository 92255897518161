import { Roles } from './Role';
import { Profile } from './Profile';
import { UserLevelType } from './UserLevel';
import { TopBarHighlight } from './TopBarHighlight';

export interface User {
  id: number;
  email: string;
  username: string;
  role: Roles;
  profile: Profile | null;
  createdAt: string;
  level: UserLevelType;
  experience: number;
  topBarHighlights: TopBarHighlight[];
}

export enum InvitedUserStatus {
  // eslint-disable-next-line no-unused-vars
  REGISTERED = 'REGISTERED',
  // eslint-disable-next-line no-unused-vars
  PURCHASED = 'PURCHASED',
  // eslint-disable-next-line no-unused-vars
  MAXED_OUT = 'MAXED_OUT',
}

export interface PublicUserSocialStats {
  creations: number;
  sales: number;
  userLikes: number;
  likes: number;
  level: UserLevelType;
}

export interface UserCollectionParts {
  totalParts: number;
  verifiedParts: number;
}

export interface UserStats {
  salesVolume: number;
  sales: number;
  followers: number;
  likes: number;
}

export interface PublicUserProfile {
  username: string;
  level: UserLevelType;
  profileImage?: string;
  description?: string;
  linkedIn?: string;
  twitter?: string;
  facebook?: string;
  tiktok?: string;
  youtube?: string;
  instagram?: string;
}
