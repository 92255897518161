import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  fetchUsersStart,
  fetchUsersSuccess,
  fetchUsersFail,
  updateUserStart,
  updateUserSuccess,
  updateUserFail,
  deleteUserStart,
  deleteUserSuccess,
  deleteUserFail,
  fetchCurrentUserStart,
  fetchCurrentUserSuccess,
  fetchCurrentUserFail,
  fetchUserStart,
  fetchUserSuccess,
  fetchUserFail,
  fetchAllUsersFail,
  fetchAllUsersStart,
  fetchAllUsersSuccess,
  updateUserInfoStart,
  updateUserInfoSuccess,
  updateUserInfoFail,
  updateUserBalanceFail,
  updateUserBalanceStart,
  updateUserBalanceSuccess,
  checkIsUsernameTakenStart,
  checkIsUsernameTakenSuccess,
  checkIsUsernameTakenFail,
  updateUserPasswordStart,
  updateUserPasswordSuccess,
  updateUserPasswordFail,
  updateUserSocialMediaStart,
  updateUserSocialMediaSuccess,
  updateUserSocialMediaFail,
  refreshCurrentUserProfileStart,
  refreshCurrentUserProfileSuccess,
  refreshCurrentUserProfileFail,
  fetchInvitedUsersStart,
  fetchInvitedUsersFail,
  fetchInvitedUsersSuccess,
  fetchPublicUserProfileStart,
  fetchPublicUserProfileSuccess,
  fetchPublicUserProfileFail,
  fetchPublicUserSocialStatsStart,
  fetchPublicUserSocialStatsSuccess,
  fetchPublicUserSocialStatsFail,
  fetchInvitedUsersSalesCountFail,
  fetchInvitedUsersSalesCountStart,
  fetchInvitedUsersSalesCountSuccess,
  addSetToUserCollectionSuccess,
  addSetToUserCollectionFail,
  addSetToUserCollectionStart,
  fetchUserCollectionPartsStart,
  fetchUserCollectionPartsSuccess,
  fetchUserCollectionPartsFail,
  removeSelfAddedSetFail,
  removeSelfAddedSetStart,
  removeSelfAddedSetSuccess,
  fetchUserStatsStart,
  fetchUserStatsSuccess,
  fetchUserStatsFail,
  userSelfDeleteStart,
  userSelfDeleteSuccess,
  userSelfDeleteFail,
  updateUserEmailStart,
  updateUserEmailSuccess,
  updateUserEmailFail,
  fetchCanUserWithdrawStart,
  fetchCanUserWithdrawSuccess,
  fetchCanUserWithdrawFail,
  fetchHasPasswordStart,
  fetchHasPasswordSuccess,
  fetchHasPasswordFail,
  createUserFail,
  createUserStart,
  createUserSuccess,
} from './actions';
import { IntlShape } from 'react-intl';
import { showToast } from '../../utility/toast/toast';
import { translate } from '../../utility/messageTranslator/translate';
import { User } from '../../domain/User';
import { getFormData } from '../../utility/form/formData';
import { SocialMediaType } from '../../component/Public/Profile/GeneralInformation/SocialAccounts/SocialAccounts';
import { UserLevelType } from '../../domain/UserLevel';
import { Roles } from 'src/domain/Role';

export type UserUpdateRequest = {
  id: number;
  email: string;
  firstName: string;
  lastName: string;
  description: string;
  address: string;
  businessName: string;
  registrationCode: string;
  username: string;
  userLevel: UserLevelType;
  role: Roles;
};

export type UserUpdateInfoRequest = {
  description: string;
  address: string;
  businessName: string;
  registrationCode: string;
  username: string;
  vatNumber: string;
  country: string;
  profileImage: File;
  email?: string;
};

export type UserUpdatePasswordRequest = {
  currentPassword?: string;
  passwordRepeat: string;
  password: string;
};

export type UserUpdateSocialMediaRequest = {
  facebook: string;
  twitter: string;
  linkedin: string;
  tiktok: string;
  youtube: string;
  instagram: string;
  type: SocialMediaType;
};

export type UpdateUserBalanceRequest = {
  id: number;
  bricks: number;
  eurAmount: number;
};

export type CheckIsUsernameTakenRequest = {
  username: string;
};

export type UserCreateRequest = {
  email: string;
  firstName: string;
  lastName: string;
  description: string;
  address: string;
  businessName: string;
  registrationCode: string;
  username: string;
  userLevel: UserLevelType;
  role: Roles;
};

const API_URL = '/users';

export const fetchUser = (id: number) => (dispatch: Dispatch) => {
  dispatch(fetchUserStart());
  return axios
    .get(`${API_URL}/${id}`)
    .then((response) => {
      dispatch(fetchUserSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserFail(err?.response?.data?.error));
    });
};

export const fetchUsers = (params: ListParams) => (dispatch: Dispatch) => {
  dispatch(fetchUsersStart());
  return axios
    .get(API_URL, { params })
    .then((response) => {
      dispatch(fetchUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUsersFail(err?.response?.data?.error));
    });
};

export const fetchAllUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchAllUsersStart());
  return axios
    .get(API_URL)
    .then((response) => {
      dispatch(fetchAllUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchAllUsersFail(err?.response?.data?.error));
    });
};

export const createUser =
  (inputs: UserCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createUserStart());
    return axios
      .post(API_URL, {
        ...inputs,
      })
      .then((response) => {
        dispatch(createUserSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.USER_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(createUserFail(err?.response?.data?.message));
      });
  };

export const updateUser =
  (inputs: UserUpdateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserStart());
    return axios
      .patch(`${API_URL}/${inputs.id}`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserFail(err?.response?.data?.message));
      });
  };

export const updateUserBalance =
  (inputs: UpdateUserBalanceRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateUserBalanceStart());
    return axios
      .patch(`${API_URL}/${inputs.id}/balance`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserBalanceSuccess());
        showToast(translate(intl, 'SUCCESS.USER_BALANCE_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserBalanceFail(err?.response?.data?.message));
      });
  };

export const updateUserInfo =
  (inputs: UserUpdateInfoRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserInfoStart());
    return axios
      .patch(`${API_URL}/update-info`, getFormData(inputs))
      .then((response) => {
        dispatch(updateUserInfoSuccess());
        dispatch(fetchCurrentUserSuccess(response.data));
        showToast(translate(intl, 'SUCCESS.USER_INFO_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(updateUserInfoFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const deleteUser =
  (id: number, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(deleteUserStart());
    return axios
      .delete(`${API_URL}/${id}`)
      .then(() => {
        dispatch(deleteUserSuccess());
        showToast(translate(intl, 'SUCCESS.USER_DELETE'), 'success');
      })
      .catch((err) => {
        dispatch(deleteUserFail(err?.response?.data?.message));
      });
  };

export const fetchCurrentUser = () => (dispatch: Dispatch) => {
  dispatch(fetchCurrentUserStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      const user = response.data as User;
      dispatch(fetchCurrentUserSuccess(user));
    })
    .catch((err) => {
      dispatch(fetchCurrentUserFail());
    });
};

export const fetchHasPassword = () => (dispatch: Dispatch) => {
  dispatch(fetchHasPasswordStart());

  return axios
    .get(`${API_URL}/has-password`)
    .then((response) => {
      dispatch(fetchHasPasswordSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchHasPasswordFail());
    });
};

export const fetchCanUserWithdraw = () => (dispatch: Dispatch) => {
  dispatch(fetchCanUserWithdrawStart());

  return axios
    .get(`${API_URL}/withdraw`)
    .then((response) => {
      dispatch(fetchCanUserWithdrawSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchCanUserWithdrawFail());
    });
};

export const checkIsUsernameTaken =
  (params: CheckIsUsernameTakenRequest) => (dispatch: Dispatch) => {
    dispatch(checkIsUsernameTakenStart());

    return axios
      .get(`${API_URL}/check-username`, {
        params,
      })
      .then((response) => {
        dispatch(checkIsUsernameTakenSuccess(response.data));
      })
      .catch((err) => {
        dispatch(checkIsUsernameTakenFail(err?.response?.data?.message));
      });
  };

export const updateUserPassword =
  (inputs: UserUpdatePasswordRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateUserPasswordStart());
    return axios
      .patch(`${API_URL}/password`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserPasswordSuccess());
        showToast(translate(intl, 'SUCCESS.PASSWORD_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          updateUserPasswordFail(translate(intl, err?.response?.data?.message)),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateUserSocialMedia =
  (inputs: UserUpdateSocialMediaRequest, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(updateUserSocialMediaStart());
    return axios
      .patch(`${API_URL}/social-media`, {
        ...inputs,
      })
      .then(() => {
        dispatch(updateUserSocialMediaSuccess());
        showToast(translate(intl, 'SUCCESS.SOCIAL_MEDIA_UPDATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          updateUserSocialMediaFail(
            translate(intl, err?.response?.data?.message),
          ),
        );
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const refreshCurrentProfileUser = () => (dispatch: Dispatch) => {
  dispatch(refreshCurrentUserProfileStart());

  return axios
    .get(`${API_URL}/me`)
    .then((response) => {
      const user = response.data as User;
      dispatch(refreshCurrentUserProfileSuccess(user));
    })
    .catch((err) => {
      dispatch(refreshCurrentUserProfileFail());
    });
};

export const fetchInvitedUsers = () => (dispatch: Dispatch) => {
  dispatch(fetchInvitedUsersStart());
  return axios
    .get(`${API_URL}/invited`)
    .then((response) => {
      dispatch(fetchInvitedUsersSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchInvitedUsersFail(err?.response?.data?.error));
    });
};

export const fetchPublicUserProfile =
  (username: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPublicUserProfileStart());
    return axios
      .get(`${API_URL}/public/${username}`)
      .then((response) => {
        dispatch(fetchPublicUserProfileSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicUserProfileFail(err?.response?.data?.error));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchPublicUserSocialStats =
  (username: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPublicUserSocialStatsStart());
    return axios
      .get(`${API_URL}/public/stats/${username}`)
      .then((response) => {
        dispatch(fetchPublicUserSocialStatsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPublicUserSocialStatsFail(err?.response?.data?.error));
      });
  };

export const fetchInvitedUsersSalesCount = () => (dispatch: Dispatch) => {
  dispatch(fetchInvitedUsersSalesCountStart());
  return axios
    .get(`${API_URL}/invited/sales-count`)
    .then((response) => {
      dispatch(fetchInvitedUsersSalesCountSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchInvitedUsersSalesCountFail(err?.response?.data?.error));
    });
};

export const addSetToUserCollection =
  (setId: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(addSetToUserCollectionStart());
    return axios
      .patch(`${API_URL}/collection/${setId}`)
      .then(() => {
        dispatch(addSetToUserCollectionSuccess());
        showToast(
          translate(intl, 'SUCCESS.ADD_SET_TO_USER_COLLECTION'),
          'success',
        );
      })
      .catch((err) => {
        dispatch(addSetToUserCollectionFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const fetchUserCollectionParts =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchUserCollectionPartsStart());
    return axios
      .get(`${API_URL}/collection/parts`)
      .then((response) => {
        dispatch(fetchUserCollectionPartsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchUserCollectionPartsFail(err?.response?.data?.error));
      });
  };

export const removeSelfAddedSet =
  (setId: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(removeSelfAddedSetStart());
    return axios
      .delete(`${API_URL}/collection/${setId}`)
      .then(() => {
        dispatch(removeSelfAddedSetSuccess());
        showToast(translate(intl, 'SUCCES.SELF_ADDED_SET_REMOVED'), 'success');
      })
      .catch((err) => {
        dispatch(removeSelfAddedSetFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const onFetchUserStats = () => (dispatch: Dispatch) => {
  dispatch(fetchUserStatsStart());
  return axios
    .get(`${API_URL}/user-stats`)
    .then((response) => {
      dispatch(fetchUserStatsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserStatsFail(err?.response?.data?.error));
    });
};

export const userSelfDelete =
  (id: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(userSelfDeleteStart());
    return axios
      .delete(`${API_URL}/self-delete/${id}`)
      .then(() => {
        dispatch(userSelfDeleteSuccess());
      })
      .catch((err) => {
        dispatch(userSelfDeleteFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const updateUserEmail =
  (id: string, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(updateUserEmailStart());
    return axios
      .patch(`${API_URL}/change-email/${id}`)
      .then(() => {
        dispatch(updateUserEmailSuccess());
      })
      .catch((err) => {
        dispatch(updateUserEmailFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
