export const routes = {
  homepage: '/',
  privacyPolicy: '/privacy-policy',
  login: '/login',
  register: '/register',
  remind: '/remind-password',
  resetPassword: '/password-reset/:code',
  registrationConfirmation: '/registration-confirmation/:code',
  profile: {
    generalInformation: '/profile/general-information',
    myWallet: '/profile/my-wallet',
    myCreations: {
      list: '/profile/my-creations/list',
      create: '/profile/my-creations/create',
      edit: '/profile/my-creations/:id',
      createBundle: '/profile/my-creations/create-bundle',
      editBundle: '/profile/my-creations/bundle/:id',
    },
    myLevel: '/profile/levels-and-rewards',
    contentDownload: '/profile/content-download',
    affiliateTab: '/profile/affiliate',
    myCollection: '/profile/my-collection',
    myProfile: '/user/:username',
    analyticsTab: '/profile/analytics',
  },
  dynamicPage: '/:locale/:slug',
  admin: '/admin',
  publicFaq: '/faq',
  public: {
    privacyPolicy: '/privacy-policy',
    termsOfService: '/terms-of-service',
    refundPolicy: '/refund-policy',
    affiliate: '/affiliate',
    earnMoney: '/earn-money',
    aboutUs: '/about-us',
    generalInfo: '/general-info',
    contactUs: '/contact',
    accountDeletion: '/account-deletion/:token',
    emailChange: '/email-change/:token',
    shippingPolicy: '/shipping-policy',
  },
  users: {
    list: '/admin/users',
    edit: '/admin/users/:id',
    create: '/admin/users/new',
  },
  faq: {
    list: '/admin/faq',
    create: '/admin/faq/new',
    edit: '/admin/faq/:id',
  },
  translations: '/admin/translations',
  promotions: {
    list: '/admin/promotions',
  },
  comingSoon: '/coming-soon',
  languages: '/admin/languages',
  categories: {
    list: '/admin/categories',
  },
  topBarHighlights: {
    list: '/admin/top-bar-highlights',
  },
  products: {
    list: '/admin/products',
    edit: '/admin/products/:id',
    setEdit: '/admin/products/set/:id',
    bundleEdit: '/admin/products/bundle/:id',
    create: '/admin/products/create',
    createBundle: '/admin/products/create-bundle',
    bigBuyList: 'admin/products/big-buy-items',
    brickShopList: '/admin/products/brick-shop',
  },
  publicProducts: {
    list: '/products',
    product: '/product/:id',
  },
  brickShop: {
    list: '/brick-shop',
  },
  publicProfile: {
    profile: '/user/:username',
  },
  cart: '/cart',
  productReview: {
    list: '/admin/reviews',
  },
  payment: {
    list: '/admin/payments',
  },
  order: {
    list: '/admin/orders',
  },
  themes: {
    list: '/admin/themes',
  },
  withdrawal: {
    list: '/admin/withdrawals',
  },
  packages: {
    list: '/admin/packages',
    edit: '/admin/packages/:id',
    create: '/admin/packages/create',
  },
  contents: {
    list: '/admin/contents',
    edit: '/admin/contents/:id',
    create: '/admin/contents/create',
  },
  contactUs: '/contact-us',
  staticPages: {
    list: '/admin/static-pages',
  },
  refCode: '/referral/:refCode',
};
