import * as actionTypes from './actionTypes';
import { UserActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import {
  PublicUserProfile,
  PublicUserSocialStats,
  User,
  UserCollectionParts,
  UserStats,
} from '../../domain/User';
import { ListResults } from '../../common/List/List';
import { CartItem } from '../../domain/Cart';
import { PurchaseType } from '../product/service';

export type UserStateType = {
  user: User | null;
  userLoading: boolean;
  userError: HttpError;
  usersList: ListResults<User> | null;
  usersLoading: boolean;
  usersError: HttpError;
  userUpdateLoading: boolean;
  userUpdateError: HttpError;
  userUpdateSuccess: boolean;
  userDeleteLoading: boolean;
  userDeleteError: HttpError;
  userDeleteSuccess: boolean;
  usersListUpdateNeeded: boolean;
  prevCurrentUser: User | null;
  currentUser: User | null;
  currentUserLoading: boolean;
  hasPassword: boolean;
  hasPasswordLoading: boolean;
  hasPasswordNeedUpdate: boolean;
  allUsersError: HttpError;
  allUsersList: ListResults<User> | null;
  allUsersLoading: boolean;
  userUpdateInfoLoading: boolean;
  userUpdateInfoError: HttpError;
  userUpdateInfoSuccess: boolean;
  createdUser: User | null;
  updateUserBalanceLoading: boolean;
  updateUserBalanceError: HttpError;
  updateUserBalanceSuccess: boolean;
  isUsernameTaken: boolean;
  isUsernameTakenError: HttpError;
  isUsernameTakenLoading: boolean;
  updateUserPasswordError: HttpError;
  updateUserPasswordLoading: boolean;
  updateUserPasswordSuccess: boolean;
  updateUserSocialMediaError: HttpError;
  updateUserSocialMediaLoading: boolean;
  updateUserSocialMediaSuccess: boolean;
  refreshUserLoading: boolean;
  invitedUsers: User[];
  invitedUsersLoading: boolean;
  invitedUsersError: HttpError;
  publicUserProfile: PublicUserProfile | null;
  publicUserProfileLoading: boolean;
  publicUserProfileError: HttpError;
  publicUserSocialStats: PublicUserSocialStats | null;
  publicUserSocialStatsLoading: boolean;
  publicUserSocialStatsError: HttpError;
  cartItems: CartItem[];
  invitedUsersSalesCount: number;
  invitedUsersSalesCountLoading: boolean;
  invitedUsersSalesCountError: HttpError;
  addSetToUserCollectionError: HttpError;
  addSetToUserCollectionLoading: boolean;
  addSetToUserCollectionSuccess: boolean;
  userCollectionParts: UserCollectionParts | null;
  userCollectionPartsLoading: boolean;
  userCollectionPartsError: HttpError;
  userCollectionPartsUpdateNeeded: boolean;
  selfAddedProductsListUpdateNeeded: boolean;
  removeSelfAddedSetLoading: boolean;
  removeSelfAddedSetSuccess: boolean;
  removeSelfAddedSetError: HttpError;
  userStats: UserStats | null;
  userStatsLoading: boolean;
  userStatsError: HttpError;
  userSelfDeleteLoading: boolean;
  userSelfDeleteError: HttpError;
  userSelfDeleteSuccess: boolean;
  userEmailChangeLoading: boolean;
  userEmailChangeError: HttpError;
  userEmailChangeSuccess: boolean;
  canUserWithdrawLoading: boolean;
  canUserWithdraw: boolean;
  userCreateLoading: boolean;
  userCreateError: HttpError;
  userCreateSuccess: boolean;
};

export type UserActionType = UserStateType & {
  type: UserActionTypes;
  cartItem: CartItem;
};

export const initialState: UserStateType = {
  user: null,
  userLoading: false,
  userError: null,
  userDeleteError: null,
  userDeleteLoading: false,
  userDeleteSuccess: false,
  userUpdateError: null,
  userUpdateLoading: false,
  userUpdateSuccess: false,
  usersError: null,
  usersList: null,
  usersLoading: true,
  usersListUpdateNeeded: false,
  prevCurrentUser: null,
  currentUser: null,
  currentUserLoading: false,
  hasPassword: false,
  hasPasswordLoading: false,
  hasPasswordNeedUpdate: false,
  allUsersError: null,
  allUsersList: null,
  allUsersLoading: true,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
  createdUser: null,
  updateUserBalanceSuccess: false,
  updateUserBalanceError: null,
  updateUserBalanceLoading: false,
  isUsernameTaken: false,
  isUsernameTakenError: null,
  isUsernameTakenLoading: false,
  updateUserPasswordError: null,
  updateUserPasswordLoading: false,
  updateUserPasswordSuccess: false,
  updateUserSocialMediaError: null,
  updateUserSocialMediaLoading: false,
  updateUserSocialMediaSuccess: false,
  refreshUserLoading: false,
  invitedUsers: [],
  invitedUsersLoading: false,
  invitedUsersError: null,
  publicUserProfile: null,
  publicUserProfileLoading: false,
  publicUserProfileError: null,
  publicUserSocialStats: null,
  publicUserSocialStatsLoading: false,
  publicUserSocialStatsError: null,
  cartItems: localStorage.getItem('cartItems')
    ? JSON.parse(localStorage.getItem('cartItems') ?? '')
    : [],
  invitedUsersSalesCount: 0,
  invitedUsersSalesCountLoading: false,
  invitedUsersSalesCountError: null,
  addSetToUserCollectionLoading: false,
  addSetToUserCollectionError: null,
  addSetToUserCollectionSuccess: false,
  userCollectionParts: null,
  userCollectionPartsLoading: false,
  userCollectionPartsError: null,
  userCollectionPartsUpdateNeeded: false,
  selfAddedProductsListUpdateNeeded: false,
  removeSelfAddedSetLoading: false,
  removeSelfAddedSetSuccess: false,
  removeSelfAddedSetError: null,
  userStats: null,
  userStatsLoading: false,
  userStatsError: null,
  userSelfDeleteLoading: false,
  userSelfDeleteError: null,
  userSelfDeleteSuccess: false,
  userEmailChangeLoading: false,
  userEmailChangeError: null,
  userEmailChangeSuccess: false,
  canUserWithdraw: false,
  canUserWithdrawLoading: false,
  userCreateError: null,
  userCreateLoading: false,
  userCreateSuccess: false,
};

const fetchCurrentUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: true,
});

const fetchCurrentUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  currentUser: action.currentUser,
  currentUserLoading: false,
});

const fetchCurrentUserFail = (state: UserStateType): UserStateType => ({
  ...state,
  currentUserLoading: false,
});

const fetchHasPasswordStart = (state: UserStateType): UserStateType => ({
  ...state,
  hasPasswordLoading: true,
});

const fetchHasPasswordSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  hasPassword: action.hasPassword,
  hasPasswordLoading: false,
  hasPasswordNeedUpdate: false,
});

const fetchHasPasswordFail = (state: UserStateType): UserStateType => ({
  ...state,
  hasPasswordLoading: false,
});

const fetchUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userLoading: true,
});

const fetchUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  user: action.user,
  userLoading: false,
  userError: null,
});

const fetchUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  userLoading: false,
});

const fetchUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  usersLoading: true,
  userUpdateSuccess: false,
  updateUserBalanceSuccess: false,
});

const fetchUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersList: action.usersList,
  usersLoading: false,
  usersError: null,
  usersListUpdateNeeded: false,
});

const fetchUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  usersError: action.usersError,
  usersLoading: false,
});

const fetchAllUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  allUsersLoading: true,
});

const fetchAllUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersList: action.allUsersList,
  allUsersLoading: false,
  allUsersError: null,
});

const fetchAllUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  allUsersError: action.allUsersError,
  allUsersLoading: false,
});

const updateUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: true,
});

const updateUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: null,
  userUpdateSuccess: true,
  usersListUpdateNeeded: true,
});

const updateUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateLoading: false,
  userUpdateError: action.userUpdateError,
});

const updateUserBalanceStart = (state: UserStateType): UserStateType => ({
  ...state,
  updateUserBalanceLoading: true,
});

const updateUserBalanceSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  updateUserBalanceLoading: false,
  updateUserBalanceError: null,
  updateUserBalanceSuccess: true,
});

const updateUserBalanceFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  updateUserBalanceLoading: false,
  updateUserBalanceError: action.updateUserBalanceError,
});

const deleteUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: true,
});

const deleteUserSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: null,
  userDeleteSuccess: true,
  usersListUpdateNeeded: true,
});

const deleteUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userDeleteLoading: false,
  userDeleteError: action.userDeleteError,
});

const userEmailChangeStart = (state: UserStateType): UserStateType => ({
  ...state,
  userEmailChangeLoading: true,
});

const userEmailChangeSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userEmailChangeLoading: false,
  userEmailChangeSuccess: true,
});

const userEmailChangeFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userEmailChangeLoading: false,
  userEmailChangeError: action.userEmailChangeError,
});

const userSelfDeleteStart = (state: UserStateType): UserStateType => ({
  ...state,
  userSelfDeleteLoading: true,
});

const userSelfDeleteSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userSelfDeleteLoading: false,
  userSelfDeleteSuccess: true,
});

const userSelfDeleteFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userSelfDeleteLoading: false,
  userSelfDeleteError: action.userSelfDeleteError,
});

const updateUserInfoStart = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: true,
});

const updateUserInfoSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: null,
  userUpdateInfoSuccess: true,
});

const resetUpdateUserInfo = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: null,
  userUpdateInfoSuccess: false,
});

const updateUserInfoFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userUpdateInfoLoading: false,
  userUpdateInfoError: action.userUpdateInfoError,
});

const updateUserSocialMediaStart = (state: UserStateType): UserStateType => ({
  ...state,
  updateUserSocialMediaLoading: true,
});

const updateUserSocialMediaSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  updateUserSocialMediaLoading: false,
  updateUserSocialMediaError: null,
  updateUserSocialMediaSuccess: true,
});

const updateUserSocialMediaFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  updateUserSocialMediaLoading: false,
  updateUserSocialMediaError: action.updateUserSocialMediaError,
});

const checkIsUsernameTakenStart = (state: UserStateType): UserStateType => ({
  ...state,
  isUsernameTakenLoading: true,
});

const checkIsUsernameTakenSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  isUsernameTakenLoading: false,
  isUsernameTakenError: null,
  isUsernameTaken: action.isUsernameTaken,
});

const checkIsUsernameTakenFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  isUsernameTakenLoading: false,
  isUsernameTakenError: action.isUsernameTakenError,
});

const updateUserPasswordStart = (state: UserStateType): UserStateType => ({
  ...state,
  updateUserPasswordLoading: false,
});

const updateUserPasswordSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  updateUserPasswordLoading: false,
  updateUserPasswordSuccess: true,
  hasPasswordNeedUpdate: true,
});

const updateUserPasswordFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  updateUserPasswordLoading: false,
  updateUserPasswordError: action.updateUserPasswordError,
});

const refreshUserProfileStart = (state: UserStateType): UserStateType => ({
  ...state,
  refreshUserLoading: true,
});

const refreshUserProfileSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => {
  if (state.currentUser) {
    return {
      ...state,
      refreshUserLoading: false,
      prevCurrentUser: state.currentUser,
      currentUser: action.currentUser,
    };
  }

  return {
    ...state,
    refreshUserLoading: false,
  };
};

const refreshUserProfileFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userError: action.userError,
  refreshUserLoading: false,
});

const fetchInvitedUsersStart = (state: UserStateType): UserStateType => ({
  ...state,
  invitedUsersLoading: true,
});

const fetchInvitedUsersSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  invitedUsers: action.invitedUsers,
  invitedUsersLoading: false,
  invitedUsersError: null,
});

const fetchInvitedUsersFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  invitedUsersError: action.invitedUsersError,
  invitedUsersLoading: false,
});

const fetchPublicUserProfileStart = (state: UserStateType): UserStateType => ({
  ...state,
  publicUserProfileLoading: true,
});

const fetchPublicUserProfileSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  publicUserProfile: action.publicUserProfile,
  publicUserProfileLoading: false,
  publicUserProfileError: null,
});

const fetchPublicUserProfileFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  publicUserProfileError: action.publicUserProfileError,
  publicUserProfileLoading: false,
});

const addSetToUserCollectionStart = (state: UserStateType): UserStateType => ({
  ...state,
  addSetToUserCollectionLoading: true,
  selfAddedProductsListUpdateNeeded: false,
});

const addSetToUserCollectionSuccess = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  addSetToUserCollectionLoading: false,
  addSetToUserCollectionSuccess: true,
  selfAddedProductsListUpdateNeeded: true,
  userCollectionPartsUpdateNeeded: true,
});

const addSetToUserCollectionFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  addSetToUserCollectionLoading: false,
  addSetToUserCollectionError: action.addSetToUserCollectionError,
});

const removeSelfAddedSetStart = (state: UserStateType): UserStateType => ({
  ...state,
  removeSelfAddedSetLoading: true,
  selfAddedProductsListUpdateNeeded: false,
});

const removeSelfAddedSetSuccess = (state: UserStateType): UserStateType => ({
  ...state,
  removeSelfAddedSetLoading: false,
  removeSelfAddedSetSuccess: true,
  selfAddedProductsListUpdateNeeded: true,
  userCollectionPartsUpdateNeeded: true,
});

const removeSelfAddedSetFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  removeSelfAddedSetLoading: false,
  removeSelfAddedSetError: action.removeSelfAddedSetError,
});

const fetchPublicUserSocialStatsStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  publicUserSocialStatsLoading: true,
});

const fetchPublicUserSocialStatsSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  publicUserSocialStats: action.publicUserSocialStats,
  publicUserSocialStatsLoading: false,
  publicUserSocialStatsError: null,
});

const fetchPublicUserSocialStatsFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  publicUserSocialStatsError: action.publicUserSocialStatsError,
  publicUserSocialStatsLoading: false,
});

const resetPublicUserProfileStore = (state: UserStateType): UserStateType => ({
  ...state,
  publicUserProfile: null,
  publicUserProfileError: null,
  publicUserProfileLoading: false,
});

const fetchInvitedUsersSalesCountStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  invitedUsersSalesCountLoading: true,
});

const fetchInvitedUsersSalesCountSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  invitedUsersSalesCount: action.invitedUsersSalesCount,
  invitedUsersSalesCountLoading: false,
  invitedUsersSalesCountError: null,
});

const fetchInvitedUsersSalesCountFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  invitedUsersSalesCountError: action.invitedUsersSalesCountError,
  invitedUsersSalesCountLoading: false,
});

const addToCart = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => {
  const cartItems = [...state.cartItems, ...action.cartItems];

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const removeFromCart = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => {
  const cartItems = state.cartItems.filter(
    (prevCartItem) =>
      !action.cartItems
        .map((cartItem) => cartItem.uuid)
        .includes(prevCartItem.uuid),
  );

  localStorage.setItem('cartItems', JSON.stringify(cartItems));

  return {
    ...state,
    cartItems,
  };
};

const fetchUserCollectionPartsStart = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  userCollectionPartsLoading: true,
  userCollectionPartsUpdateNeeded: false,
});

const fetchUserCollectionPartsSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCollectionParts: action.userCollectionParts,
  userCollectionPartsLoading: false,
  userCollectionPartsError: null,
});

const fetchUserCollectionPartsFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCollectionPartsError: action.userCollectionPartsError,
  userCollectionPartsLoading: false,
});

const fetchUserStatsStart = (state: UserStateType): UserStateType => ({
  ...state,
  userStatsLoading: true,
});

const fetchUserStatsSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userStats: action.userStats,
  userStatsLoading: false,
  userStatsError: null,
});

const fetchUserStatsFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userStatsError: action.userStatsError,
  userStatsLoading: false,
});

const canUserWithdrawStart = (state: UserStateType): UserStateType => ({
  ...state,
  canUserWithdrawLoading: true,
  canUserWithdraw: false,
});

const canUserWithdrawSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  canUserWithdrawLoading: false,
  canUserWithdraw: action.canUserWithdraw,
});

const canUserWithdrawFail = (state: UserStateType): UserStateType => ({
  ...state,
  canUserWithdrawLoading: false,
  canUserWithdraw: false,
});

const createUserStart = (state: UserStateType): UserStateType => ({
  ...state,
  userCreateLoading: true,
});

const createUserSuccess = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: null,
  userCreateSuccess: true,
  usersListUpdateNeeded: true,
  createdUser: action.createdUser,
});

const createUserFail = (
  state: UserStateType,
  action: UserActionType,
): UserStateType => ({
  ...state,
  userCreateLoading: false,
  userCreateError: action.userCreateError,
});

const resetCartStore = (state: UserStateType): UserStateType => ({
  ...state,
  cartItems: [],
});

const resetBrickShopCartStore = (state: UserStateType): UserStateType => {
  const cartItems = state.cartItems.filter(
    (cartItem) => cartItem.type !== PurchaseType.BRICKS,
  );

  return {
    ...state,
    cartItems: cartItems,
  };
};

const resetAddSetToUserCollectionStore = (
  state: UserStateType,
): UserStateType => ({
  ...state,
  addSetToUserCollectionLoading: false,
  addSetToUserCollectionSuccess: false,
  addSetToUserCollectionError: null,
});

const resetUserInfoStore = (state: UserStateType): UserStateType => ({
  ...state,
  userUpdateInfoError: null,
  userUpdateInfoLoading: false,
  userUpdateInfoSuccess: false,
});

const resetUserStore = (state: UserStateType): UserStateType => ({
  ...initialState,
  currentUser: state.currentUser,
});
const resetCreateUserStore = (state: UserStateType): UserStateType => ({
  ...state,
  createdUser: null,
});

const completelyResetUserStore = (): UserStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_USER_START:
      return fetchUserStart(state);
    case actionTypes.FETCH_USER_SUCCESS:
      return fetchUserSuccess(state, action);
    case actionTypes.FETCH_USER_FAIL:
      return fetchUserFail(state, action);
    case actionTypes.FETCH_USERS_START:
      return fetchUsersStart(state);
    case actionTypes.FETCH_USERS_SUCCESS:
      return fetchUsersSuccess(state, action);
    case actionTypes.FETCH_USERS_FAIL:
      return fetchUsersFail(state, action);
    case actionTypes.FETCH_CAN_USER_WITHDRAW_START:
      return canUserWithdrawStart(state);
    case actionTypes.FETCH_CAN_USER_WITHDRAW_SUCCESS:
      return canUserWithdrawSuccess(state, action);
    case actionTypes.FETCH_CAN_USER_WITHDRAW_FAIL:
      return canUserWithdrawFail(state);
    case actionTypes.FETCH_ALL_USERS_START:
      return fetchAllUsersStart(state);
    case actionTypes.FETCH_ALL_USERS_SUCCESS:
      return fetchAllUsersSuccess(state, action);
    case actionTypes.FETCH_ALL_USERS_FAIL:
      return fetchAllUsersFail(state, action);
    case actionTypes.UPDATE_USER_START:
      return updateUserStart(state);
    case actionTypes.UPDATE_USER_SUCCESS:
      return updateUserSuccess(state);
    case actionTypes.UPDATE_USER_FAIL:
      return updateUserFail(state, action);
    case actionTypes.UPDATE_USER_BALANCE_START:
      return updateUserBalanceStart(state);
    case actionTypes.UPDATE_USER_BALANCE_SUCCESS:
      return updateUserBalanceSuccess(state);
    case actionTypes.UPDATE_USER_BALANCE_FAIL:
      return updateUserBalanceFail(state, action);
    case actionTypes.DELETE_USER_START:
      return deleteUserStart(state);
    case actionTypes.DELETE_USER_SUCCESS:
      return deleteUserSuccess(state);
    case actionTypes.DELETE_USER_FAIL:
      return deleteUserFail(state, action);
    case actionTypes.FETCH_ME_START:
      return fetchCurrentUserStart(state);
    case actionTypes.FETCH_ME_SUCCESS:
      return fetchCurrentUserSuccess(state, action);
    case actionTypes.FETCH_ME_FAIL:
      return fetchCurrentUserFail(state);
    case actionTypes.FETCH_HAS_PASSWORD_START:
      return fetchHasPasswordStart(state);
    case actionTypes.FETCH_HAS_PASSWORD_SUCCESS:
      return fetchHasPasswordSuccess(state, action);
    case actionTypes.FETCH_HAS_PASSWORD_FAIL:
      return fetchHasPasswordFail(state);
    case actionTypes.UPDATE_USER_INFO_START:
      return updateUserInfoStart(state);
    case actionTypes.UPDATE_USER_INFO_SUCCESS:
      return updateUserInfoSuccess(state);
    case actionTypes.UPDATE_USER_INFO_FAIL:
      return updateUserInfoFail(state, action);
    case actionTypes.RESET_UPDATE_USER_INFO:
      return resetUpdateUserInfo(state);
    case actionTypes.UPDATE_USER_PASSWORD_START:
      return updateUserPasswordStart(state);
    case actionTypes.UPDATE_USER_PASSWORD_SUCCESS:
      return updateUserPasswordSuccess(state);
    case actionTypes.UPDATE_USER_PASSWORD_FAIL:
      return updateUserPasswordFail(state, action);
    case actionTypes.UPDATE_USER_SOCIAL_MEDIA_START:
      return updateUserSocialMediaStart(state);
    case actionTypes.UPDATE_USER_SOCIAL_MEDIA_SUCCESS:
      return updateUserSocialMediaSuccess(state);
    case actionTypes.UPDATE_USER_SOCIAL_MEDIA_FAIL:
      return updateUserSocialMediaFail(state, action);
    case actionTypes.CHECK_IS_USERNAME_TAKEN_START:
      return checkIsUsernameTakenStart(state);
    case actionTypes.CHECK_IS_USERNAME_TAKEN_SUCCESS:
      return checkIsUsernameTakenSuccess(state, action);
    case actionTypes.CHECK_IS_USERNAME_TAKEN_FAIL:
      return checkIsUsernameTakenFail(state, action);
    case actionTypes.REFRESH_CURRENT_USER_PROFILE_START:
      return refreshUserProfileStart(state);
    case actionTypes.REFRESH_CURRENT_USER_PROFILE_SUCCESS:
      return refreshUserProfileSuccess(state, action);
    case actionTypes.REFRESH_CURRENT_USER_PROFILE_FAIL:
      return refreshUserProfileFail(state, action);
    case actionTypes.FETCH_INVITED_USERS_START:
      return fetchInvitedUsersStart(state);
    case actionTypes.FETCH_INVITED_USERS_SUCCESS:
      return fetchInvitedUsersSuccess(state, action);
    case actionTypes.FETCH_INVITED_USERS_FAIL:
      return fetchInvitedUsersFail(state, action);
    case actionTypes.FETCH_PUBLIC_USER_PROFILE_START:
      return fetchPublicUserProfileStart(state);
    case actionTypes.FETCH_PUBLIC_USER_PROFILE_SUCCESS:
      return fetchPublicUserProfileSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_USER_PROFILE_FAIL:
      return fetchPublicUserProfileFail(state, action);
    case actionTypes.FETCH_PUBLIC_USER_SOCIAL_STATS_START:
      return fetchPublicUserSocialStatsStart(state);
    case actionTypes.FETCH_PUBLIC_USER_SOCIAL_STATS_SUCCESS:
      return fetchPublicUserSocialStatsSuccess(state, action);
    case actionTypes.FETCH_PUBLIC_USER_SOCIAL_STATS_FAIL:
      return fetchPublicUserSocialStatsFail(state, action);
    case actionTypes.RESET_PUBLIC_USER_PROFILE_STORE:
      return resetPublicUserProfileStore(state);
    case actionTypes.FETCH_INVITED_USERS_SALES_COUNT_START:
      return fetchInvitedUsersSalesCountStart(state);
    case actionTypes.FETCH_INVITED_USERS_SALES_COUNT_SUCCESS:
      return fetchInvitedUsersSalesCountSuccess(state, action);
    case actionTypes.FETCH_INVITED_USERS_SALES_COUNT_FAIL:
      return fetchInvitedUsersSalesCountFail(state, action);
    case actionTypes.ADD_TO_CART:
      return addToCart(state, action);
    case actionTypes.REMOVE_FROM_CART:
      return removeFromCart(state, action);
    case actionTypes.RESET_CART_STORE:
      return resetCartStore(state);
    case actionTypes.RESET_BRICK_SHOP_CART_STORE:
      return resetBrickShopCartStore(state);
    case actionTypes.ADD_SET_TO_USER_COLLECTION_START:
      return addSetToUserCollectionStart(state);
    case actionTypes.ADD_SET_TO_USER_COLLECTION_SUCCESS:
      return addSetToUserCollectionSuccess(state);
    case actionTypes.ADD_SET_TO_USER_COLLECTION_FAIL:
      return addSetToUserCollectionFail(state, action);
    case actionTypes.REMOVE_SELF_ADDED_SET_START:
      return removeSelfAddedSetStart(state);
    case actionTypes.REMOVE_SELF_ADDED_SET_SUCCESS:
      return removeSelfAddedSetSuccess(state);
    case actionTypes.REMOVE_SELF_ADDED_SET_FAIL:
      return removeSelfAddedSetFail(state, action);
    case actionTypes.FETCH_USER_COLLECTION_PARTS_START:
      return fetchUserCollectionPartsStart(state);
    case actionTypes.FETCH_USER_COLLECTION_PARTS_SUCCESS:
      return fetchUserCollectionPartsSuccess(state, action);
    case actionTypes.FETCH_USER_COLLECTION_PARTS_FAIL:
      return fetchUserCollectionPartsFail(state, action);
    case actionTypes.FETCH_USER_STATS_START:
      return fetchUserStatsStart(state);
    case actionTypes.FETCH_USER_STATS_SUCCESS:
      return fetchUserStatsSuccess(state, action);
    case actionTypes.FETCH_USER_STATS_FAIL:
      return fetchUserStatsFail(state, action);
    case actionTypes.USER_SELF_DELETE_START:
      return userSelfDeleteStart(state);
    case actionTypes.USER_SELF_DELETE_SUCCESS:
      return userSelfDeleteSuccess(state);
    case actionTypes.USER_SELF_DELETE_FAIL:
      return userSelfDeleteFail(state, action);
    case actionTypes.UPDATE_USER_EMAIL_START:
      return userEmailChangeStart(state);
    case actionTypes.UPDATE_USER_EMAIL_SUCCESS:
      return userEmailChangeSuccess(state);
    case actionTypes.UPDATE_USER_EMAIL_FAIL:
      return userEmailChangeFail(state, action);
    case actionTypes.CREATE_USER_START:
      return createUserStart(state);
    case actionTypes.CREATE_USER_SUCCESS:
      return createUserSuccess(state, action);
    case actionTypes.CREATE_USER_FAIL:
      return createUserFail(state, action);
    case actionTypes.RESET_CREATED_USER:
      return resetCreateUserStore(state);
    case actionTypes.RESET_ADD_SET_TO_USER_COLLECTION_STORE:
      return resetAddSetToUserCollectionStore(state);
    case actionTypes.RESET_USER_INFO_STORE:
      return resetUserInfoStore(state);
    case actionTypes.RESET_USER_STORE:
      return resetUserStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserStore();
    default:
      return state;
  }
};

export default reducer;
