import * as actionTypes from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { DailyRewardsStatus, UserReward } from '../../domain/UserReward';

export const createUserRewardStart = () => ({
  type: actionTypes.CREATE_USER_REWARD_START,
});

export const createUserRewardSuccess = () => ({
  type: actionTypes.CREATE_USER_REWARD_SUCCESS,
});

export const createUserRewardFail = (createUserRewardError: HttpError) => ({
  type: actionTypes.CREATE_USER_REWARD_FAIL,
  createUserRewardError,
});

export const fetchUserDailyRewardsStatusStart = () => ({
  type: actionTypes.FETCH_USER_DAILY_REWARDS_STATUS_START,
});

export const fetchUserDailyRewardsStatusSuccess = (
  dailyRewardsStatus: DailyRewardsStatus,
) => ({
  type: actionTypes.FETCH_USER_DAILY_REWARDS_STATUS_SUCCESS,
  dailyRewardsStatus,
});

export const fetchUserDailyRewardsStatusFail = (
  createUserRewardError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_DAILY_REWARDS_STATUS_FAIL,
  createUserRewardError,
});

export const createUserShareRewardStart = () => ({
  type: actionTypes.CREATE_USER_SHARE_REWARD_START,
});

export const createUserShareRewardSuccess = () => ({
  type: actionTypes.CREATE_USER_SHARE_REWARD_SUCCESS,
});

export const createUserShareRewardFail = (
  createUserShareRewardError: HttpError,
) => ({
  type: actionTypes.CREATE_USER_SHARE_REWARD_FAIL,
  createUserShareRewardError,
});

export const fetchUserRecentRewardsStart = () => ({
  type: actionTypes.FETCH_USER_RECENT_REWARDS_START,
});

export const fetchUserRecentRewardsSuccess = (
  recentUserRewards: UserReward[],
) => ({
  type: actionTypes.FETCH_USER_RECENT_REWARDS_SUCCESS,
  recentUserRewards,
});

export const fetchUserRecentRewardsFail = (
  recentUserRewardsError: HttpError,
) => ({
  type: actionTypes.FETCH_USER_RECENT_REWARDS_FAIL,
  recentUserRewardsError,
});

export const fetchUserRewardsStart = () => ({
  type: actionTypes.FETCH_USER_REWARDS_START,
});

export const fetchUserRewardsSuccess = (userRewards: UserReward[]) => ({
  type: actionTypes.FETCH_USER_REWARDS_SUCCESS,
  userRewards,
});

export const fetchUserRewardsFail = (userRewardsError: HttpError) => ({
  type: actionTypes.FETCH_USER_REWARDS_FAIL,
  userRewardsError,
});

export const resetUserLevelStore = () => ({
  type: actionTypes.RESET_USER_REWARD_STORE,
});

export const logout = () => ({
  type: actionTypes.LOGOUT,
});
