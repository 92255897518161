import { HttpError } from '../../config/Axios/axios-instance';
import { Tag } from '../../domain/Tag';
import { TagActionTypes } from './actionTypes';
import * as actionTypes from './actionTypes';

export type TagStateType = {
  productTagSuggestions: Tag[] | null;
  productTagSuggestionsLoading: boolean;
  productTagSuggestionsError: HttpError;
};

export type TagActionType = TagStateType & {
  type: TagActionTypes;
};

export const initialState: TagStateType = {
  productTagSuggestions: null,
  productTagSuggestionsLoading: false,
  productTagSuggestionsError: null,
};

const fetchProductTagSuggestionsStart = (
  state: TagStateType,
): TagStateType => ({
  ...state,
  productTagSuggestionsLoading: true,
});

const fetchProductTagSuggestionsSuccess = (
  state: TagStateType,
  action: TagActionType,
): TagStateType => ({
  ...state,
  productTagSuggestions: action.productTagSuggestions,
  productTagSuggestionsLoading: false,
  productTagSuggestionsError: null,
});

const fetchProductTagSuggestionsFail = (
  state: TagStateType,
  action: TagActionType,
): TagStateType => ({
  ...state,
  productTagSuggestionsError: action.productTagSuggestionsError,
  productTagSuggestionsLoading: false,
});

const resetProductTagSuggestions = (state: TagStateType): TagStateType => ({
  ...state,
  productTagSuggestions: null,
  productTagSuggestionsLoading: false,
  productTagSuggestionsError: null,
});

const reducer = (state = initialState, action: TagActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_PRODUCT_TAG_SUGGESTIONS_START:
      return fetchProductTagSuggestionsStart(state);
    case actionTypes.FETCH_PRODUCT_TAG_SUGGESTIONS_SUCCESS:
      return fetchProductTagSuggestionsSuccess(state, action);
    case actionTypes.FETCH_PRODUCT_TAG_SUGGESTIONS_FAIL:
      return fetchProductTagSuggestionsFail(state, action);
    case actionTypes.RESET_PRODUCT_TAG_SUGGESTIONS:
      return resetProductTagSuggestions(state);
    default:
      return state;
  }
};

export default reducer;
