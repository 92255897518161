export const FETCH_ORDER_LIST_START = 'FETCH_ORDER_LIST_START';
export const FETCH_ORDER_LIST_SUCCESS = 'FETCH_ORDER_LIST_SUCCESS';
export const FETCH_ORDER_LIST_FAIL = 'FETCH_ORDER_LIST_FAIL';

export const CREATE_ORDER_START = 'CREATE_ORDER_START';
export const CREATE_ORDER_SUCCESS = 'CREATE_ORDER_SUCCESS';
export const CREATE_ORDER_FAIL = 'CREATE_ORDER_FAIL';

export const CREATE_BRICK_SHOP_ORDER_START = 'CREATE_BRICK_SHOP_ORDER_START';
export const CREATE_BRICK_SHOP_ORDER_SUCCESS =
  'CREATE_BRICK_SHOP_ORDER_SUCCESS';
export const CREATE_BRICK_SHOP_ORDER_FAIL = 'CREATE_BRICK_SHOP_ORDER_FAIL';

export const FETCH_USER_ORDERS_START = 'FETCH_USER_ORDERS_START';
export const FETCH_USER_ORDERS_SUCCESS = 'FETCH_USER_ORDERS_SUCCESS';
export const FETCH_USER_ORDERS_FAIL = 'FETCH_USER_ORDERS_FAIL';

export const FETCH_ALL_USER_ORDERS_START = 'FETCH_ALL_USER_ORDERS_START';
export const FETCH_ALL_USER_ORDERS_SUCCESS = 'FETCH_ALL_USER_ORDERS_SUCCESS';
export const FETCH_ALL_USER_ORDERS_FAIL = 'FETCH_ALL_USER_ORDERS_FAIL';

export const RESET_ORDER_STORE = 'RESET_ORDER_STORE';
export const RESET_BRICK_SHOP_ORDER_STORE = 'RESET_BRICK_SHOP_ORDER_STORE';
export const LOGOUT = 'LOGOUT';

export type OrderActionTypes =
  | typeof FETCH_ORDER_LIST_START
  | typeof FETCH_ORDER_LIST_SUCCESS
  | typeof FETCH_ORDER_LIST_FAIL
  | typeof CREATE_ORDER_START
  | typeof CREATE_ORDER_SUCCESS
  | typeof CREATE_ORDER_FAIL
  | typeof RESET_ORDER_STORE
  | typeof FETCH_USER_ORDERS_START
  | typeof FETCH_USER_ORDERS_SUCCESS
  | typeof FETCH_USER_ORDERS_FAIL
  | typeof FETCH_ALL_USER_ORDERS_START
  | typeof FETCH_ALL_USER_ORDERS_SUCCESS
  | typeof FETCH_ALL_USER_ORDERS_FAIL
  | typeof CREATE_BRICK_SHOP_ORDER_START
  | typeof CREATE_BRICK_SHOP_ORDER_SUCCESS
  | typeof CREATE_BRICK_SHOP_ORDER_FAIL
  | typeof RESET_BRICK_SHOP_ORDER_STORE
  | typeof LOGOUT;
