import * as actionTypes from './actionTypes';
import { DiscordActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { DiscordAuthLink, DiscordConnection } from 'src/domain/Discord';

export type DiscordStateType = {
  authLinkLoading: boolean;
  authLink: DiscordAuthLink | null;
  authLinkError: HttpError | null;
  connectionLoading: boolean;
  connection: DiscordConnection | null;
  connectionError: HttpError | null;
};

export type DiscordActionType = DiscordStateType & {
  type: DiscordActionTypes;
};

export const initialState: DiscordStateType = {
  authLinkLoading: false,
  authLink: null,
  authLinkError: null,

  connectionLoading: false,
  connection: null,
  connectionError: null,
};

const fetchDiscordAuthLinkStart = (
  state: DiscordStateType,
): DiscordStateType => ({
  ...state,
  authLinkLoading: true,
});

const fetchDiscordAuthLinkSuccess = (
  state: DiscordStateType,
  action: DiscordActionType,
): DiscordStateType => ({
  ...state,
  authLinkLoading: false,
  authLink: action.authLink,
  authLinkError: null,
});

const fetchDiscordAuthLinkFail = (
  state: DiscordStateType,
  action: DiscordActionType,
): DiscordStateType => ({
  ...state,
  authLinkLoading: false,
  authLinkError: action.authLinkError,
});

const fetchDiscordConnectionStart = (
  state: DiscordStateType,
): DiscordStateType => ({
  ...state,
  connectionLoading: true,
});

const fetchDiscordConnectionSuccess = (
  state: DiscordStateType,
  action: DiscordActionType,
): DiscordStateType => ({
  ...state,
  connectionLoading: false,
  connection: action.connection,
  connectionError: null,
});

const fetchDiscordConnectionFail = (
  state: DiscordStateType,
  action: DiscordActionType,
): DiscordStateType => ({
  ...state,
  connectionLoading: false,
  connectionError: action.connectionError,
});

const resetDiscordStore = (state: DiscordStateType): DiscordStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: DiscordActionType) => {
  switch (action.type) {
    case actionTypes.FETCH_DISCORD_AUTH_LINK_START:
      return fetchDiscordAuthLinkStart(state);
    case actionTypes.FETCH_DISCORD_AUTH_LINK_SUCCESS:
      return fetchDiscordAuthLinkSuccess(state, action);
    case actionTypes.FETCH_DISCORD_AUTH_LINK_FAIL:
      return fetchDiscordAuthLinkFail(state, action);
    case actionTypes.FETCH_DISCORD_CONNECTION_START:
      return fetchDiscordConnectionStart(state);
    case actionTypes.FETCH_DISCORD_CONNECTION_SUCCESS:
      return fetchDiscordConnectionSuccess(state, action);
    case actionTypes.FETCH_DISCORD_CONNECTION_FAIL:
      return fetchDiscordConnectionFail(state, action);
    case actionTypes.RESET_DISCORD_STORE:
      return resetDiscordStore(state);
    default:
      return state;
  }
};

export default reducer;
