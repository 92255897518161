import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  createUserRewardFail,
  createUserRewardStart,
  createUserRewardSuccess,
  createUserShareRewardFail,
  createUserShareRewardStart,
  createUserShareRewardSuccess,
  fetchUserDailyRewardsStatusFail,
  fetchUserDailyRewardsStatusStart,
  fetchUserDailyRewardsStatusSuccess,
  fetchUserRecentRewardsFail,
  fetchUserRecentRewardsStart,
  fetchUserRecentRewardsSuccess,
  fetchUserRewardsFail,
  fetchUserRewardsStart,
  fetchUserRewardsSuccess,
} from './actions';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { showToast } from '../../utility/toast/toast';

const API_URL = '/user-rewards';

export const createUserReward =
  (userId: number, rewardId: number, intl: IntlShape) =>
  (dispatch: Dispatch) => {
    dispatch(createUserRewardStart());
    return axios
      .post(`${API_URL}/${userId}/${rewardId}`)
      .then(() => {
        dispatch(createUserRewardSuccess());
        showToast(translate(intl, 'SUCCESS.REWARD_CREATE'), 'success');
      })
      .catch((err) => {
        dispatch(
          createUserRewardFail(translate(intl, err?.response?.data?.message)),
        );
      });
  };

export const createUserShareReward =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createUserShareRewardStart());
    return axios
      .post(`${API_URL}/share`)
      .then(() => {
        dispatch(createUserShareRewardSuccess());
      })
      .catch((err) => {
        dispatch(
          createUserShareRewardFail(
            translate(intl, err?.response?.data?.message),
          ),
        );
      });
  };

export const fetchUserDailyRewardsStatus =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchUserDailyRewardsStatusStart());
    return axios
      .get(`${API_URL}/daily-rewards`)
      .then((response) => {
        dispatch(fetchUserDailyRewardsStatusSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          fetchUserDailyRewardsStatusFail(
            translate(intl, err?.response?.data?.message),
          ),
        );
      });
  };

export const fetchUserRecentRewards =
  (intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchUserRecentRewardsStart());
    return axios
      .get(`${API_URL}/recent`)
      .then((response) => {
        dispatch(fetchUserRecentRewardsSuccess(response.data));
      })
      .catch((err) => {
        dispatch(
          fetchUserRecentRewardsFail(
            translate(intl, err?.response?.data?.message),
          ),
        );
      });
  };

export const fetchUserRewards = () => (dispatch: Dispatch) => {
  dispatch(fetchUserRewardsStart());
  return axios
    .get(`${API_URL}/user-rewards`)
    .then((response) => {
      dispatch(fetchUserRewardsSuccess(response.data));
    })
    .catch((err) => {
      dispatch(fetchUserRewardsFail(err?.response?.data?.error));
    });
};
