export const CREATE_USER_REWARD_START = 'CREATE_USER_REWARD_START';
export const CREATE_USER_REWARD_SUCCESS = 'CREATE_USER_REWARD_SUCCESS';
export const CREATE_USER_REWARD_FAIL = 'CREATE_USER_REWARD_FAIL';

export const CREATE_USER_SHARE_REWARD_START = 'CREATE_USER_SHARE_REWARD_START';
export const CREATE_USER_SHARE_REWARD_SUCCESS =
  'CREATE_USER_SHARE_REWARD_SUCCESS';
export const CREATE_USER_SHARE_REWARD_FAIL = 'CREATE_USER_SHARE_REWARD_FAIL';

export const FETCH_USER_DAILY_REWARDS_STATUS_START =
  'FETCH_USER_DAILY_REWARDS_STATUS_START';
export const FETCH_USER_DAILY_REWARDS_STATUS_SUCCESS =
  'FETCH_USER_DAILY_REWARDS_STATUS_SUCCESS';
export const FETCH_USER_DAILY_REWARDS_STATUS_FAIL =
  'FETCH_USER_DAILY_REWARDS_STATUS_FAIL';

export const FETCH_USER_RECENT_REWARDS_START =
  'FETCH_USER_RECENT_REWARDS_START';
export const FETCH_USER_RECENT_REWARDS_SUCCESS =
  'FETCH_USER_RECENT_REWARDS_SUCCESS';
export const FETCH_USER_RECENT_REWARDS_FAIL = 'FETCH_USER_RECENT_REWARDS_FAIL';

export const FETCH_USER_REWARDS_START = 'FETCH_USER_REWARDS_START';
export const FETCH_USER_REWARDS_SUCCESS = 'FETCH_USER_REWARDS_SUCCESS';
export const FETCH_USER_REWARDS_FAIL = 'FETCH_USER_REWARDS_FAIL';

export const RESET_USER_REWARD_STORE = 'RESET_USER_REWARD_STORE';

export const LOGOUT = 'LOGOUT';

export type UserRewardActionTypes =
  | typeof CREATE_USER_REWARD_START
  | typeof CREATE_USER_REWARD_SUCCESS
  | typeof CREATE_USER_REWARD_FAIL
  | typeof FETCH_USER_DAILY_REWARDS_STATUS_START
  | typeof FETCH_USER_DAILY_REWARDS_STATUS_SUCCESS
  | typeof FETCH_USER_DAILY_REWARDS_STATUS_FAIL
  | typeof FETCH_USER_RECENT_REWARDS_START
  | typeof FETCH_USER_RECENT_REWARDS_SUCCESS
  | typeof FETCH_USER_RECENT_REWARDS_FAIL
  | typeof CREATE_USER_SHARE_REWARD_START
  | typeof CREATE_USER_SHARE_REWARD_SUCCESS
  | typeof CREATE_USER_SHARE_REWARD_FAIL
  | typeof RESET_USER_REWARD_STORE
  | typeof FETCH_USER_REWARDS_START
  | typeof FETCH_USER_REWARDS_SUCCESS
  | typeof FETCH_USER_REWARDS_FAIL
  | typeof LOGOUT;
