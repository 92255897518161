import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import { ListParams } from '../../hooks/useList/useList';
import {
  createPaymentFail,
  createPaymentStart,
  createPaymentSuccess,
  fetchPaymentListFail,
  fetchPaymentListStart,
  fetchPaymentListSuccess,
} from './actions';
import { showToast } from '../../utility/toast/toast';
import { IntlShape } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { PaymentType } from '../../domain/Payment';

const API_URL = '/payments';

export type PaymentCreateRequest = {
  packageId?: number;
  locationUrl: string;
  currency: string;
  orderId?: number;
  paymentType: PaymentType;
};

export const fetchPaymentList =
  (params: ListParams, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(fetchPaymentListStart());
    return axios
      .get(API_URL, { params })
      .then((response) => {
        dispatch(fetchPaymentListSuccess(response.data));
      })
      .catch((err) => {
        dispatch(fetchPaymentListFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };

export const createPayment =
  (inputs: PaymentCreateRequest, intl: IntlShape) => (dispatch: Dispatch) => {
    dispatch(createPaymentStart());
    return axios
      .post(API_URL, { ...inputs })
      .then((response) => {
        dispatch(createPaymentSuccess(response.data));
      })
      .catch((err) => {
        dispatch(createPaymentFail(err?.response?.data?.message));
        showToast(translate(intl, err?.response?.data?.message), 'error');
      });
  };
