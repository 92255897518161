import React, { ReactNode, useState, useEffect, useMemo } from 'react';
import styles from './Layout.module.scss';
import Sidebar from '../Sidebar/Sidebar';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import cx from 'classnames';
import useWindowSize from '../../hooks/useWindowSize/useWindowSize';
import Navigation from '../Navigation/Navigation';
import { routes } from '../../config/Router/routes';
import { useIntl } from 'react-intl';
import { translate } from '../../utility/messageTranslator/translate';
import { StoreState } from '../../config/StoreProvider/StoreProvider';
import { connect } from 'react-redux';
import { User } from '../../domain/User';
import { Roles } from '../../domain/Role';
import PublicLayout from './PublicLayout/PublicLayout';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import { setPostLoginUrl } from '../../store/auth/actions';
import { ThemeProvider as MuiThemeProvider } from '@mui/material/styles';
import { adminTheme, publicTheme } from '../../config/mui-themes';
import { Icon } from '@iconify/react';
import { differenceBy } from 'lodash';
import { showToast } from '../../utility/toast/toast';
import * as profileService from '../../store/profile/service';
import { UserReward } from '../../domain/UserReward';
import * as userRewardService from '../../store/user-reward/service';

export type Props = {
  children: ReactNode;
  currentUser: User | null;
  isAuthenticated: boolean;
  onSetPostLoginUrl: () => void;
  postLoginUrl: string | null;
  prevCurrentUser: User | null;
  onLoginStatusUpdate: () => void;
  userRewards: UserReward[] | null;
  prevUserRewards: UserReward[] | null;
  onFetchUserRewards: () => void;
};

export type SubNavigationItem = {
  label: string;
  to: string;
};

export type NavigationItem = {
  label: string;
  to: string;
  icon?: ReactNode;
  subNavigations?: Array<SubNavigationItem>;
};

export type NavigationGroup = {
  label: string;
  items: NavigationItem[];
};

const MOBILE_BREAK_POINT = 900;
const ICON_SIZE = '1.5rem';

const Layout = ({
  children,
  currentUser,
  isAuthenticated,
  onSetPostLoginUrl,
  postLoginUrl,
  prevCurrentUser,
  onLoginStatusUpdate,
  userRewards,
  prevUserRewards,
  onFetchUserRewards,
}: Props) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const location = useLocation();
  const intl = useIntl();
  const navigate = useNavigate();

  const { width } = useWindowSize();

  useEffect(() => {
    if (userRewards && prevUserRewards) {
      const newRewards = differenceBy(userRewards, prevUserRewards, 'id');

      if (newRewards.length > 0) {
        for (const newReward of newRewards) {
          showToast(translate(intl, `NEW_REWARD.${newReward.type}`), 'success');
        }
      }
    }
  }, [prevUserRewards, userRewards]);

  useEffect(() => {
    window.scroll({ top: 0 });
    setIsMobileMenuOpen(false);
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'pageview',
    });
  }, [location.key]);

  useEffect(() => {
    if (isAuthenticated && postLoginUrl) {
      navigate(`${postLoginUrl}?loggedIn=true`);
      onSetPostLoginUrl();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    if (
      currentUser &&
      currentUser.role === Roles.USER &&
      currentUser?.profile?.isFirstLogin
    ) {
      navigate(`${routes.profile.generalInformation}?product_tour_id=563829`);
      window.location.reload();

      onLoginStatusUpdate();
    }
  }, [currentUser]);

  useEffect(() => {
    if (currentUser) {
      onFetchUserRewards();
    }
  }, [currentUser]);

  const isAdminSide = useMemo(
    () => location.pathname.includes(routes.admin),
    [location],
  );

  const SIDEBAR_ITEMS = [
    {
      label: translate(intl, 'NAVIGATION.GROUP_ECOMMERCE'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PRODUCTS'),
          to: routes.products.list,
          icon: (
            <Icon
              icon="solar:tag-price-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN, Roles.PRODUCT_MANAGER],
          subNavigations: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PRODUCTS_LIST'),
              to: routes.products.list,
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PRODUCTS_CREATE'),
              to: routes.products.create,
            },
            {
              label: translate(
                intl,
                'NAVIGATION.SIDEBAR_PRODUCTS_BIGBUY_ITEMS',
              ),
              to: routes.products.bigBuyList,
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PRODUCTS_BRICK_SHOP'),
              to: routes.products.brickShopList,
            },
          ],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_REVIEWS'),
          to: routes.productReview.list,
          icon: (
            <Icon
              icon="solar:document-add-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PAYMENTS'),
          to: routes.payment.list,
          icon: (
            <Icon
              icon="solar:hand-money-line-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_ORDERS'),
          to: routes.order.list,
          icon: (
            <Icon
              icon="solar:cart-3-linear"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PROMOTIONS'),
          to: routes.promotions.list,
          icon: (
            <Icon icon="mdi:megaphone" width={ICON_SIZE} height={ICON_SIZE} />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_FAQ'),
          to: routes.faq.list,
          icon: <Icon icon="wpf:faq" width={ICON_SIZE} height={ICON_SIZE} />,
          roles: [Roles.ADMIN],
          subNavigations: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_FAQ_LIST'),
              to: routes.faq.list,
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_FAQ_CREATE'),
              to: routes.faq.create,
            },
          ],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CATEGORIES'),
          to: routes.categories.list,
          icon: (
            <Icon
              icon="solar:archive-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN, Roles.PRODUCT_MANAGER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_WITHDRAWAL'),
          to: routes.withdrawal.list,
          icon: (
            <Icon
              icon="solar:wallet-money-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label:
        currentUser?.role === Roles.ADMIN
          ? translate(intl, 'NAVIGATION.GROUP_GLOBAL')
          : '',
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_USERS'),
          to: routes.users.list,
          icon: (
            <Icon
              icon="solar:users-group-two-rounded-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          subNavigations: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_USERS_LIST'),
              to: routes.users.list,
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_USERS_CREATE'),
              to: routes.users.create,
            },
          ],
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_LANGUAGES'),
          to: routes.languages,
          icon: (
            <Icon
              icon="solar:sort-by-alphabet-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TRANSLATIONS'),
          to: routes.translations,
          icon: (
            <Icon
              icon="solar:notebook-bookmark-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_STATIC_PAGES'),
          to: routes.staticPages.list,
          icon: (
            <Icon
              icon="solar:document-text-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
      ],
    },
    {
      label: translate(intl, 'NAVIGATION.OTHER_SETTINGS'),
      items: [
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_THEMES'),
          to: routes.themes.list,
          icon: (
            <Icon
              icon="fluent:dark-theme-20-filled"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN, Roles.PRODUCT_MANAGER],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_PACKAGES'),
          to: routes.packages.list,
          icon: (
            <Icon
              icon="solar:backpack-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
          subNavigations: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PACKAGE_LIST'),
              to: routes.packages.list,
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_PACKAGE_CREATE'),
              to: routes.packages.create,
            },
          ],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_TOP_BAR_HIGHLIGHTS'),
          to: routes.topBarHighlights.list,
          icon: (
            <Icon
              icon="ic:baseline-highlight"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
        },
        {
          label: translate(intl, 'NAVIGATION.SIDEBAR_CONTENTS'),
          to: routes.contents.list,
          icon: (
            <Icon
              icon="solar:document-add-bold-duotone"
              width={ICON_SIZE}
              height={ICON_SIZE}
            />
          ),
          roles: [Roles.ADMIN],
          subNavigations: [
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_CONTENT_LIST'),
              to: routes.contents.list,
            },
            {
              label: translate(intl, 'NAVIGATION.SIDEBAR_CONTENT_CREATE'),
              to: routes.contents.create,
            },
          ],
        },
      ],
    },
  ];

  useEffect(() => {
    if (isMobileMenuOpen) {
      window.scroll({ top: 0 });
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isMobileMenuOpen]);

  const getNavigationGroups = () => {
    if (currentUser?.role === Roles.USER) {
      return [];
    }

    const sidebarItems = SIDEBAR_ITEMS.map((sidebarItemGroup) => ({
      label: sidebarItemGroup.label,
      items: sidebarItemGroup.items.filter((sidebarItem) => {
        return (
          !!currentUser?.role && sidebarItem.roles.includes(currentUser.role)
        );
      }),
    }));

    return sidebarItems;
  };

  if (!location.pathname.includes(routes.admin)) {
    return (
      <MuiThemeProvider theme={isAdminSide ? adminTheme : publicTheme}>
        <PublicLayout>{children}</PublicLayout>
      </MuiThemeProvider>
    );
  }

  return (
    <MuiThemeProvider theme={isAdminSide ? adminTheme : publicTheme}>
      <div className={styles.container}>
        {width && width >= MOBILE_BREAK_POINT && (
          <Sidebar navigationGroups={getNavigationGroups()} />
        )}
        <div
          className={cx(styles.rightSection, {
            [styles.noScroll]: isMobileMenuOpen,
          })}
        >
          <Navigation
            onDrawerClick={() => setIsMobileMenuOpen((prev) => !prev)}
            isMobileMenuOpen={isMobileMenuOpen}
          />
          <div className={styles.content}>{children}</div>
        </div>
      </div>
      {isMobileMenuOpen && width && width < MOBILE_BREAK_POINT && (
        <div className={styles.mobileDrawer}>
          {getNavigationGroups().map((navigationGroup) => (
            <div className={styles.navigationGroup} key={navigationGroup.label}>
              <div className={styles.groupName}>{navigationGroup.label}</div>
              {navigationGroup.items.map((item) => (
                <NavLink
                  key={item.label}
                  to={item.to}
                  className={({ isActive }) =>
                    cx(styles.navigationItem, {
                      [styles.activeSubItem]: isActive,
                    })
                  }
                >
                  {item.label}
                </NavLink>
              ))}
            </div>
          ))}
        </div>
      )}
    </MuiThemeProvider>
  );
};

const mapStateToProps = (state: StoreState) => ({
  currentUser: state.user.currentUser,
  prevCurrentUser: state.user.prevCurrentUser,
  isAuthenticated: state.auth.isAuthenticated,
  postLoginUrl: state.auth.postLoginUrl,
  userRewards: state.userReward.userRewards,
  prevUserRewards: state.userReward.prevUserRewards,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onSetPostLoginUrl: () => dispatch(setPostLoginUrl(null)),
  onLoginStatusUpdate: () => dispatch(profileService.updateLoginStatus()),
  onFetchUserRewards: () => dispatch(userRewardService.fetchUserRewards()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Layout);
