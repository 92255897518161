import React, { Suspense, useEffect } from 'react';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import { connect } from 'react-redux';
import Loader from '../../common/Loader/Loader';
import { StoreState } from '../StoreProvider/StoreProvider';
import { ThunkDispatch } from 'redux-thunk';
import { AnyAction } from 'redux';
import * as authService from '../../store/auth/service';
import * as userService from '../../store/user/service';
import { routes } from './routes';
import Layout from '../../common/Layout/Layout';
import jwtDecode from 'jwt-decode';
import moment from 'moment';
import { JwtToken } from '../Axios/axios-instance';
import { logout, selectLocale } from '../../store/auth/actions';
import * as languageService from '../../store/language/service';
import { Language } from '../../domain/Language';
import { DEFAULT_LOCALE } from '../constants';
import { Locale } from '../../domain/Translation';
import { IntlProvider } from 'react-intl';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorFallback from '../../common/ErrorFallback/ErrorFallback';
import { User } from '../../domain/User';
import { SignUpSectionType } from '../../pages/Public/SignUpPage/SignUpPage';
import { Roles } from '../../domain/Role';
import { DefaultStaticPages } from 'src/domain/StaticPage';
import AffiliatePage from '../../pages/Public/ProfilePage/AffiliatePage/AffiliatePage';

const SignUpPage = React.lazy(
  () => import('../../pages/Public/SignUpPage/SignUpPage'),
);

const PasswordResetPage = React.lazy(
  () => import('../../pages/Public/PasswordResetPage/PasswordResetPage'),
);

const UsersListPage = React.lazy(
  () => import('../../pages/Admin/User/UsersListPage/UsersListPage'),
);

const UserEditPage = React.lazy(
  () => import('../../pages/Admin/User/UserEditPage/UserEditPage'),
);

const TranslationsPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Translation/TranslationListPage/TranslationListPage'
    ),
);

const PromotionsListPage = React.lazy(
  () =>
    import('../../pages/Admin/Promotion/PromotionsListPage/PromotionsListPage'),
);

const DynamicPage = React.lazy(
  () => import('../../pages/Public/DynamicPage/DynamicPage'),
);
const HomePage = React.lazy(
  () => import('../../pages/Public/HomePage/HomePage'),
);

const ComingSoonPage = React.lazy(
  () => import('../../pages/Public/ComingSoonPage/ComingSoonPage'),
);

const LanguagesListPage = React.lazy(
  () => import('../../pages/Admin/Language/LanguagesListPage'),
);

const CategoriesListPage = React.lazy(
  () =>
    import('../../pages/Admin/Category/CategoriesListPage/CategoriesListPage'),
);

const TopBarHighlightsListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/TopBarHighlight/TopBarHighlightsListPage/TopBarHighlightsListPage'
    ),
);

const ProductListPage = React.lazy(
  () => import('../../pages/Admin/Product/ProductListPage/ProductListPage'),
);

const ProductCreatePage = React.lazy(
  () => import('../../pages/Admin/Product/ProductCreatePage/ProductCreatePage'),
);

const ProductEditPage = React.lazy(
  () => import('../../pages/Admin/Product/ProductEditPage/ProductEditPage'),
);

const ProductSetEditPage = React.lazy(
  () =>
    import('../../pages/Admin/Product/ProductSetEditPage/ProductSetEditPage'),
);

const ProductReviewListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/ProductReview/ProductReviewListPage/ProductReviewListPage'
    ),
);

const PaymentListPage = React.lazy(
  () => import('../../pages/Admin/Payment/PaymentListPage/PaymentListPage'),
);

const OrderListPage = React.lazy(
  () => import('../../pages/Admin/Order/OrderListPage/OrderListPage'),
);

const ThemesListPage = React.lazy(
  () => import('../../pages/Admin/Theme/ThemesListPage/ThemesListPage'),
);

const FaqListPage = React.lazy(
  () => import('../../pages/Admin/Faq/FaqListPage/FaqListPage'),
);

const FaqCreatePage = React.lazy(
  () => import('../../pages/Admin/Faq/FaqCreatePage/FaqCreatePage'),
);

const FaqEditPage = React.lazy(
  () => import('../../pages/Admin/Faq/FaqEditPage/FaqEditPage'),
);

const WithdrawalListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Withdrawal/WithdrawalListPage/WithdrawalListPage'
    ),
);

const PackageListPage = React.lazy(
  () => import('../../pages/Admin/Package/PackageListPage/PackageListPage'),
);

const PackageCreatePage = React.lazy(
  () => import('../../pages/Admin/Package/PackageCreatePage/PackageCreatePage'),
);

const PackageEditPage = React.lazy(
  () => import('../../pages/Admin/Package/PackageEditPage/PackageEditPage'),
);

const ContentListPage = React.lazy(
  () => import('../../pages/Admin/Content/ContentListPage/ContentListPage'),
);

const ContentCreatePage = React.lazy(
  () => import('../../pages/Admin/Content/ContentCreatePage/ContentCreatePage'),
);

const ContentEditPage = React.lazy(
  () => import('../../pages/Admin/Content/ContentEditPage/ContentEditPage'),
);

const ContactUsPage = React.lazy(
  () => import('../../pages/Public/ContactUsPage/ContactUsPage'),
);

const StaticPageListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/StaticPage/StaticPageListPage/StaticPageListPage'
    ),
);

const PublicProductListPage = React.lazy(
  () => import('../../pages/Public/ProductListPage/ProductListPage'),
);

const PublicProfilePage = React.lazy(
  () => import('../../pages/Public/PublicProfilePage/PublicProfilePage'),
);

const CartPage = React.lazy(
  () => import('../../pages/Public/CartPage/CartPage'),
);

const PublicProductPage = React.lazy(
  () => import('../../pages/Public/ProductPage/ProductPage'),
);

const FaqPage = React.lazy(() => import('../../pages/Public/FAQPage/FAQpage'));

const ProfileGeneralInformationPage = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/GeneralInformationPage/GeneralInformationPage'
    ),
);

const ProfileMyWalletPage = React.lazy(
  () => import('../../pages/Public/ProfilePage/MyWallet/MyWalletPage'),
);

const ProfileMyLevelPage = React.lazy(
  () => import('../../pages/Public/ProfilePage/MyLevelPage/MyLevelPage'),
);

const ProfileMyCollectionPage = React.lazy(
  () =>
    import('../../pages/Public/ProfilePage/MyCollectionPage/MyCollectionPage'),
);

const ProfileMyCreationsListPage = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/MyCreationsListPage/MyCreationsListPage'
    ),
);

const ProfileContentDownloadPage = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/ContentDownloadPage/ContentDownloadPage'
    ),
);

const ProfileAffiliatePage = React.lazy(
  () => import('../../pages/Public/ProfilePage/AffiliatePage/AffiliatePage'),
);

const ProfileAnalyticsPage = React.lazy(
  () => import('../../pages/Public/ProfilePage/AnalyticsPage/AnalyticsPage'),
);

const MyCreationsBundlesCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/MyCreationsBundlesCreatePage/MyCreationsBundlesCreate'
    ),
);

const MyCreationsCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/MyCreationsCreatePage/MyCreationsCreatePage'
    ),
);

const MyCreationsEditPage = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/MyCreationsEditPage/MyCreationsEditPage'
    ),
);

const MyCreationsBundlesEdit = React.lazy(
  () =>
    import(
      '../../pages/Public/ProfilePage/MyCreationsBundlesEdit/MyCreationsBundlesEdit'
    ),
);

const PublicStaticPage = React.lazy(
  () => import('../../pages/Public/PublicStaticPage/PublicStaticPage'),
);

const UserDeletePage = React.lazy(
  () => import('../../pages/Public/UserDeletePage/UserDeletePage'),
);

const UserChangeEmailPage = React.lazy(
  () => import('../../pages/Public/UserChangeEmailPage/UserChangeEmailPage'),
);

const ProductBundleCreatePage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Product/ProductBundleCreatePage/ProductBundleCreatePage'
    ),
);

const BigBuyItemListPage = React.lazy(
  () =>
    import('../../pages/Admin/Product/BigBuyItemListPage/BigBuyItemListPage'),
);

const UserCreatePage = React.lazy(
  () => import('../../pages/Admin/User/UserCreatePage/UserCreatePage'),
);

const BrickShopProductListPage = React.lazy(
  () =>
    import(
      '../../pages/Admin/Product/BrickShopProductListPage/BrickShopProductListPage'
    ),
);

const PublicBrickShopProductListPage = React.lazy(
  () => import('../../pages/Public/BrickShopPage/BrickShopListPage'),
);

export type Props = {
  isInitCompleted: boolean;
  isAuthenticated: boolean;
  onTryAutoSignup: () => void;
  isCurrentUserLoading: boolean;
  refreshTokenLoading: boolean;
  onFetchCurrentUser: () => void;
  onRefreshToken: () => void;
  jwtToken: string | null;
  lastActionAt: moment.Moment | null;
  onLogout: () => void;
  onLanguageFetch: (locale: string) => void;
  language: Language | null;
  onSelectLocale: (locale: Locale) => void;
  selectedLocale: Locale;
  currentUser: User | null;
  onLanguagesInit: () => void;
  onRefreshCurrentProfileUser: () => void;
  isProfileRefreshNeeded: boolean;
};

export const Router = ({
  isInitCompleted,
  isAuthenticated,
  onTryAutoSignup,
  isCurrentUserLoading,
  onFetchCurrentUser,
  refreshTokenLoading,
  onRefreshToken,
  jwtToken,
  lastActionAt,
  onLogout,
  onLanguageFetch,
  language,
  selectedLocale,
  onSelectLocale,
  currentUser,
  onLanguagesInit,
  onRefreshCurrentProfileUser,
  isProfileRefreshNeeded,
}: Props) => {
  useEffect(() => {
    if (!jwtToken) {
      return;
    }

    const decodedJson: JwtToken = jwtDecode(jwtToken);

    if (!decodedJson) {
      return;
    }

    const difference = moment.duration(
      moment(decodedJson.exp * 1000).diff(moment()),
    );
    const differenceLastAction = moment.duration(moment().diff(lastActionAt));

    if (
      difference.asMinutes() < 5 &&
      differenceLastAction.asMinutes() < 5 &&
      !refreshTokenLoading
    ) {
      onRefreshToken();
    }

    const timeout = setTimeout(() => {
      onLogout();
    }, difference.asMilliseconds());

    return () => clearTimeout(timeout);
  }, [jwtToken, lastActionAt]);

  useEffect(() => {
    onTryAutoSignup();
  }, []);

  useEffect(() => {
    if (isProfileRefreshNeeded) {
      onRefreshCurrentProfileUser();
    }
  }, [isProfileRefreshNeeded]);

  useEffect(() => {
    onFetchCurrentUser();
  }, []);

  useEffect(() => {
    if (isAuthenticated) {
      onFetchCurrentUser();
    }
  }, [isAuthenticated]);

  useEffect(() => {
    moment.locale(DEFAULT_LOCALE);
    onLanguageFetch(DEFAULT_LOCALE);
    onLanguagesInit();
    onSelectLocale(DEFAULT_LOCALE);
  }, []);

  const mappedTranslations = language?.translations?.reduce(
    (obj, item) =>
      Object.assign(obj, {
        [item.alias]: item.value ? item.value : item.defaultValue,
      }),
    {},
  );

  const getStaticPagesRoutes = () => {
    return (
      <>
        <Route
          path={routes.public.privacyPolicy}
          element={
            <PublicStaticPage
              staticPageName={DefaultStaticPages.PRIVACY_POLICY}
            />
          }
        />
        <Route
          path={routes.public.termsOfService}
          element={
            <PublicStaticPage
              staticPageName={DefaultStaticPages.TERMS_OF_SERVICE}
            />
          }
        />
        <Route
          path={routes.public.refundPolicy}
          element={
            <PublicStaticPage
              staticPageName={DefaultStaticPages.REFUND_POLICY}
            />
          }
        />
        <Route
          path={routes.public.affiliate}
          element={
            <PublicStaticPage staticPageName={DefaultStaticPages.AFFILIATE} />
          }
        />
        <Route
          path={routes.public.earnMoney}
          element={
            <PublicStaticPage staticPageName={DefaultStaticPages.EARN_MONEY} />
          }
        />
        <Route
          path={routes.public.aboutUs}
          element={
            <PublicStaticPage staticPageName={DefaultStaticPages.ABOUT_US} />
          }
        />
        <Route
          path={routes.public.generalInfo}
          element={
            <PublicStaticPage
              staticPageName={DefaultStaticPages.GENERAL_INFO}
            />
          }
        />
        <Route
          path={routes.public.contactUs}
          element={
            <PublicStaticPage staticPageName={DefaultStaticPages.CONTACT_US} />
          }
        />

        <Route
          path={routes.public.shippingPolicy}
          element={
            <PublicStaticPage
              staticPageName={DefaultStaticPages.SHIPPING_POLICY}
            />
          }
        />

        <Route
          path={routes.public.accountDeletion}
          element={<UserDeletePage />}
        />

        <Route
          path={routes.public.emailChange}
          element={<UserChangeEmailPage />}
        />
      </>
    );
  };

  const getRoutes = () => {
    if (!isAuthenticated) {
      return (
        <>
          {getStaticPagesRoutes()}
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.login} />}
          />
          <Route
            path={routes.registrationConfirmation}
            element={<HomePage />}
          />
          <Route path={routes.login} element={<SignUpPage />} />
          <Route
            path={routes.register}
            element={<SignUpPage sectionType={SignUpSectionType.REGISTER} />}
          />
          <Route path={routes.resetPassword} element={<PasswordResetPage />} />
          <Route
            path={routes.refCode}
            element={<SignUpPage sectionType={SignUpSectionType.REGISTER} />}
          />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.cart} element={<CartPage />} />
          <Route
            path={routes.publicProducts.list}
            element={<PublicProductListPage />}
          />
          <Route
            path={routes.publicProfile.profile}
            element={<PublicProfilePage />}
          />
          <Route
            path={routes.publicProducts.product}
            element={<PublicProductPage />}
          />
          <Route
            path={routes.brickShop.list}
            element={<PublicBrickShopProductListPage />}
          />
          <Route path={routes.comingSoon} element={<ComingSoonPage />} />
          <Route path={routes.contactUs} element={<ContactUsPage />} />
          <Route path={routes.publicFaq} element={<FaqPage />} />
          <Route path="*" element={<Navigate to={routes.login} />} />
        </>
      );
    }

    if (
      isAuthenticated &&
      (currentUser?.role === Roles.ADMIN ||
        currentUser?.role === Roles.PRODUCT_MANAGER)
    ) {
      return (
        <>
          {getStaticPagesRoutes()}
          <Route
            path={routes.admin}
            element={<Navigate replace to={routes.users.list} />}
          />
          <Route path={routes.users.edit} element={<UserEditPage />} />
          <Route path={routes.users.list} element={<UsersListPage />} />
          <Route path={routes.users.create} element={<UserCreatePage />} />
          <Route path={routes.translations} element={<TranslationsPage />} />
          <Route
            path={routes.categories.list}
            element={<CategoriesListPage />}
          />
          <Route
            path={routes.topBarHighlights.list}
            element={<TopBarHighlightsListPage />}
          />
          <Route path={routes.products.list} element={<ProductListPage />} />

          <Route
            path={routes.products.create}
            element={<ProductCreatePage />}
          />

          <Route
            path={routes.products.createBundle}
            element={<ProductBundleCreatePage />}
          />
          <Route
            path={routes.products.bigBuyList}
            element={<BigBuyItemListPage />}
          />
          <Route
            path={routes.products.brickShopList}
            element={<BrickShopProductListPage />}
          />
          <Route path={routes.products.edit} element={<ProductEditPage />} />
          <Route
            path={routes.products.setEdit}
            element={<ProductSetEditPage />}
          />
          <Route
            path={routes.productReview.list}
            element={<ProductReviewListPage />}
          />
          <Route path={routes.payment.list} element={<PaymentListPage />} />
          <Route path={routes.order.list} element={<OrderListPage />} />
          <Route path={routes.themes.list} element={<ThemesListPage />} />
          <Route path={routes.packages.list} element={<PackageListPage />} />
          <Route
            path={routes.packages.create}
            element={<PackageCreatePage />}
          />
          <Route path={routes.packages.edit} element={<PackageEditPage />} />
          <Route
            path={routes.staticPages.list}
            element={<StaticPageListPage />}
          />
          <Route path={routes.dynamicPage} element={<DynamicPage />} />
          <Route path={routes.homepage} element={<HomePage />} />
          <Route path={routes.cart} element={<CartPage />} />
          <Route
            path={routes.publicProducts.list}
            element={<PublicProductListPage />}
          />
          <Route
            path={routes.publicProfile.profile}
            element={<PublicProfilePage />}
          />
          <Route
            path={routes.publicProducts.product}
            element={<PublicProductPage />}
          />
          <Route
            path={routes.brickShop.list}
            element={<PublicBrickShopProductListPage />}
          />
          <Route path={routes.languages} element={<LanguagesListPage />} />
          <Route
            path={routes.promotions.list}
            element={<PromotionsListPage />}
          />
          <Route path={routes.publicFaq} element={<FaqPage />} />
          <Route path={routes.faq.create} element={<FaqCreatePage />} />
          <Route path={routes.faq.edit} element={<FaqEditPage />} />
          <Route path={routes.faq.list} element={<FaqListPage />} />
          <Route
            path={routes.contents.create}
            element={<ContentCreatePage />}
          />
          <Route path={routes.contents.edit} element={<ContentEditPage />} />
          <Route path={routes.contents.list} element={<ContentListPage />} />
          <Route
            path={routes.withdrawal.list}
            element={<WithdrawalListPage />}
          />
          <Route path="*" element={<Navigate to={routes.admin} />} />
        </>
      );
    }

    return (
      <>
        {getStaticPagesRoutes()}
        <Route path={routes.homepage} element={<HomePage />} />
        <Route path={routes.cart} element={<CartPage />} />
        <Route
          path={routes.publicProducts.list}
          element={<PublicProductListPage />}
        />
        <Route
          path={routes.publicProfile.profile}
          element={<PublicProfilePage />}
        />
        <Route
          path={routes.publicProducts.product}
          element={<PublicProductPage />}
        />
        <Route
          path={routes.brickShop.list}
          element={<PublicBrickShopProductListPage />}
        />
        <Route path={routes.dynamicPage} element={<DynamicPage />} />
        <Route
          path={routes.profile.generalInformation}
          element={<ProfileGeneralInformationPage />}
        />
        <Route
          path={routes.profile.myWallet}
          element={<ProfileMyWalletPage />}
        />
        <Route
          path={routes.profile.myCollection}
          element={<ProfileMyCollectionPage />}
        />
        <Route path={routes.profile.myLevel} element={<ProfileMyLevelPage />} />
        <Route
          path={routes.profile.myCreations.list}
          element={<ProfileMyCreationsListPage />}
        />
        <Route
          path={routes.profile.contentDownload}
          element={<ProfileContentDownloadPage />}
        />
        <Route
          path={routes.profile.affiliateTab}
          element={<ProfileAffiliatePage />}
        />
        <Route
          path={routes.profile.analyticsTab}
          element={<ProfileAnalyticsPage />}
        />
        <Route
          path={routes.profile.myCreations.createBundle}
          element={<MyCreationsBundlesCreatePage />}
        />
        <Route
          path={routes.profile.myCreations.create}
          element={<MyCreationsCreatePage />}
        />
        <Route
          path={routes.profile.myCreations.edit}
          element={<MyCreationsEditPage />}
        />
        <Route
          path={routes.profile.myCreations.editBundle}
          element={<MyCreationsBundlesEdit />}
        />
        <Route path={routes.publicFaq} element={<FaqPage />} />
        <Route path={routes.refCode} element={<AffiliatePage />} />
        <Route
          path="*"
          element={<Navigate to={routes.profile.generalInformation} />}
        />
      </>
    );
  };

  return (
    <BrowserRouter basename="/">
      {isInitCompleted && !isCurrentUserLoading && language ? (
        <IntlProvider
          messages={mappedTranslations}
          locale={language?.locale ?? DEFAULT_LOCALE}
          defaultLocale="en"
        >
          <ErrorBoundary
            FallbackComponent={ErrorFallback}
            onReset={() => {
              window.location.reload();
            }}
          >
            <Suspense fallback={<Loader isLoading isFullScreen />}>
              <Layout>
                <Routes>{getRoutes()}</Routes>
              </Layout>
            </Suspense>
          </ErrorBoundary>
        </IntlProvider>
      ) : (
        <Loader isLoading isFullScreen />
      )}
    </BrowserRouter>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isInitCompleted: state.auth.isInitCompleted,
  isAuthenticated: state.auth.isAuthenticated,
  isCurrentUserLoading: state.user.currentUserLoading,
  refreshTokenLoading: state.auth.refreshTokenLoading,
  jwtToken: state.auth.jwtToken,
  lastActionAt: state.auth.lastStoreActionAt,
  language: state.language.language,
  selectedLocale: state.auth.selectedLocale,
  currentUser: state.user.currentUser,
  isProfileRefreshNeeded:
    state.withdrawal.requestWithdrawalSuccess ||
    !!state.payment.redirectUrl ||
    state.order.orderCreateSuccess ||
    state.productPromotion.purchasedProductPromotionSuccess ||
    !!state.payment.redirectUrl,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onTryAutoSignup: () => dispatch(authService.authCheckState()),
  onFetchCurrentUser: () => dispatch(userService.fetchCurrentUser()),
  onRefreshCurrentProfileUser: () =>
    dispatch(userService.refreshCurrentProfileUser()),
  onRefreshToken: () => dispatch(authService.refreshToken()),
  onLanguageFetch: (locale: string) =>
    dispatch(languageService.fetchLanguage(locale)),
  onLogout: () => dispatch(logout()),
  onSelectLocale: (locale: Locale) => dispatch(selectLocale(locale)),
  onLanguagesInit: () => dispatch(languageService.fetchLanguages()),
});

export default connect(mapStateToProps, mapDispatchToProps)(Router);
