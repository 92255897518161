import { Invoice } from './Invoice';
import { Package } from './Package';
import { User } from './User';

export interface Payment {
  id: number;
  amount: number;
  isPaid: boolean;
  userId: number;
  user: User;
  createdAt: string;
  invoice: Invoice;
  paymentPackage: Package;
  paymentCurrency?: PaymentCurrency;
  orderId?: number;
}

export enum PaymentCurrency {
  // eslint-disable-next-line no-unused-vars
  EUR = 'EUR',
}

export enum PaymentType {
  // eslint-disable-next-line no-unused-vars
  TOP_UP = 'TOP_UP',
  // eslint-disable-next-line no-unused-vars
  PURCHASE = 'PURCHASE',
}
