import { connect } from 'react-redux';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { StoreState } from '../../../config/StoreProvider/StoreProvider';
import * as packageService from '../../../store/package/service';
import { useEffect, useMemo, useState } from 'react';
import { Package } from '../../../domain/Package';
import BalanceTopUpCard from './BalanceTopUpCard/BalanceTopUpCard';
import styles from './BalanceTopUp.module.scss';
import BalanceTopUpOption from './BalanceTopUpOption/BalanceTopUpOption';
import stripeIcon from '../../../assets/icons/stripe.svg';
import _ from 'lodash';
import { PaymentCreateRequest } from '../../../store/payment/service';
import { IntlShape, useIntl } from 'react-intl';
import * as paymentService from '../../../store/payment/service';
import { useLocation } from 'react-router-dom';
import TopUpCardSuccess from './BalanceTopUpCard/TopUpCardSuccess/TopUpCardSuccess';
import TopUpCardFail from './BalanceTopUpCard/TopUpCardFail/TopUpCardFail';
import Loader from '../../../common/Loader/Loader';
import { translate } from '../../../utility/messageTranslator/translate';
import { resetCreatePayment } from '../../../store/payment/actions';
import { User } from '../../../domain/User';
import { useForm } from '../../../hooks/useForm/useForm';
import TextField from '../../../common/TextField/TextField';
import * as currencyService from '../../../store/currency/service';
import { PaymentType } from '../../../domain/Payment';

type Props = {
  onFetchAllPackages: () => void;
  isLoading: boolean;
  allPackages: Package[];
  onCreatePayment: (inputs: PaymentCreateRequest, intl: IntlShape) => void;
  redirectUrl: string | null;
  onClose?: () => void;
  withTitle?: boolean;
  onResetCreatePayment: () => void;
  isPaymentCreateLoading: boolean;
  currentUser: User | null;
  userCurrencyISOCodeFromLocation: string;
  onFetchStripeSupportedCurrencies: () => void;
  onFetchUserCurrencyISOCodeFromLocation: () => void;
  stripeSupportedCurrencies: string[];
  onFetchCurrencyRate: (currencyCode: string) => void;
  currencyRate: number;
  isCurrencyRateLoading: boolean;
};

export enum PaymentTypes {
  // eslint-disable-next-line no-unused-vars
  STRIPE = 'STRIPE',
}

type FormInputs = {
  currency: string;
};

const BalanceTopUp = ({
  onFetchAllPackages,
  isLoading,
  allPackages,
  onCreatePayment,
  redirectUrl,
  onClose,
  withTitle,
  onResetCreatePayment,
  isPaymentCreateLoading,
  currentUser,
  userCurrencyISOCodeFromLocation,
  onFetchUserCurrencyISOCodeFromLocation,
  stripeSupportedCurrencies,
  onFetchStripeSupportedCurrencies,
  currencyRate,
  onFetchCurrencyRate,
  isCurrencyRateLoading,
}: Props) => {
  const intl = useIntl();
  const location = useLocation();

  const [selectedPaymentType, setSelectedPaymentType] = useState<PaymentTypes>(
    PaymentTypes.STRIPE,
  );
  const [selectedPayment, setSelectedPayment] = useState<Package | null>(null);
  const [savedCurrency, setSavedCurrency] = useState<string>(
    userCurrencyISOCodeFromLocation ?? 'eur',
  );

  const currencyOptions = useMemo(
    () =>
      stripeSupportedCurrencies.map((currency) => ({
        value: currency,
        label: currency.toUpperCase(),
      })),
    [stripeSupportedCurrencies],
  );

  const INPUTS = [
    {
      name: 'currency',
      type: 'autocomplete',
      options: currencyOptions,
      value: userCurrencyISOCodeFromLocation ?? 'eur',
    },
  ];

  const handleCurrencyChange = (submitInputs: FormInputs) => {
    if (submitInputs.currency === '') {
      return;
    }
    onFetchCurrencyRate(submitInputs.currency);
  };

  const {
    inputs,
    onInputChange,
    onInputBlur,
    setNewInputObject,
    onSelectChange,
  } = useForm<FormInputs>(INPUTS, handleCurrencyChange, {
    submitOnChange: true,
  });

  const selectedCurrency = useMemo(
    () =>
      inputs.find((currency) => currency.name === 'currency')!.value as string,
    [inputs],
  );

  useEffect(() => {
    onFetchUserCurrencyISOCodeFromLocation();
    onFetchStripeSupportedCurrencies();
    onFetchCurrencyRate(selectedCurrency);
    onFetchAllPackages();
  }, []);

  useEffect(() => {
    if (selectedCurrency && selectedCurrency !== '') {
      setSavedCurrency(selectedCurrency);
    }
  }, [selectedCurrency]);

  useEffect(() => {
    if (currencyOptions.length) {
      setNewInputObject('currency', {
        options: currencyOptions,
      });
    }
  }, [currencyOptions]);

  useEffect(() => {
    if (userCurrencyISOCodeFromLocation) {
      setNewInputObject('currency', {
        value: userCurrencyISOCodeFromLocation,
      });
    }
  }, [userCurrencyISOCodeFromLocation]);

  const handleBlur = () => {
    if (selectedCurrency === '') {
      setNewInputObject('currency', {
        value: savedCurrency,
      });
    }
  };

  useEffect(() => {
    if (redirectUrl && selectedPayment) {
      // @ts-ignore
      window?.dataLayer?.push({
        event: 'initiateTopUp',
        event_category: 'Wallet',
        event_action: 'Initiate',
        event_label: 'Top Up',
        currency: 'USD',
        value: selectedPayment.price,
        items: {
          package_id: selectedPayment.id,
          quantity: 1,
        },
      });

      onResetCreatePayment();

      window.location.href = redirectUrl;
    }
  }, [redirectUrl]);

  const topUpOptions = [
    {
      label: translate(intl, 'BALANCE_TOP_UP.STRIPE_LABEL'),
      icon: stripeIcon,
      type: PaymentTypes.STRIPE,
    },
  ];

  const sortedPackages = useMemo(() => {
    return _.sortBy(
      allPackages.map((singlePackage) => ({
        ...singlePackage,
        price: singlePackage.price * currencyRate,
      })),
      'price',
    );
  }, [allPackages, currencyRate]);

  const handlePurchaseClick = (clickedPackage: Package) => {
    // @ts-ignore
    window?.dataLayer?.push({
      event: 'selectTopUpPackage',
      event_category: 'Wallet',
      event_action: 'Select',
      event_label: 'Top Up Package',
      package_id: clickedPackage.id,
    });

    setSelectedPayment(clickedPackage);

    onCreatePayment(
      {
        packageId: clickedPackage.id,
        locationUrl: location.pathname,
        currency: selectedCurrency,
        paymentType: PaymentType.TOP_UP,
      },
      intl,
    );
  };

  if (isLoading) {
    return <Loader isLoading height="25rem" />;
  }

  if (
    onClose &&
    window.location.href.includes('success=false') &&
    window.location.href.includes('type=TOP_UP')
  ) {
    return <TopUpCardFail onClose={onClose} />;
  }

  if (onClose && window.location.href.includes('success=true&amount=')) {
    return <TopUpCardSuccess onClose={onClose} currentUser={currentUser} />;
  }

  return (
    <div className={styles.allPackages}>
      {withTitle && (
        <h3 className={styles.packagesHeader}>
          {translate(intl, 'BALANCE_TOP_UP.HEADER')}
        </h3>
      )}
      <div className={styles.packagesSection}>
        <div className={styles.topUpOptionsContainer}>
          {topUpOptions.map(({ label, icon, type }) => (
            <BalanceTopUpOption
              key={label}
              label={label}
              icon={icon}
              selectedPaymentType={selectedPaymentType}
              type={type}
              setSelectedPaymentType={setSelectedPaymentType}
            />
          ))}
          <div className={styles.currencySelectionContainer}>
            <p className={styles.currencySelectionLabel}>
              {translate(intl, 'BALANCE_TOP_UP.SELECT_THE_CURRENCY_LABEL')}
            </p>
            {inputs.map((input) => (
              <TextField
                className={styles.input}
                key={input.name}
                onChange={onInputChange}
                value={input.value?.toString() ?? ''}
                label={input.label ?? ''}
                errors={input.validationErrors ?? []}
                onBlur={handleBlur}
                name={input.name}
                type={input.type}
                onInputBlur={onInputBlur}
                options={input.options}
                selectIcon={input.selectIcon}
                onSelectChange={onSelectChange}
              />
            ))}
          </div>
        </div>
        {isCurrencyRateLoading && <Loader isLoading height="15rem" />}
        <div className={styles.allPackagesContainer}>
          {!isCurrencyRateLoading &&
            sortedPackages.map((creditPackage) => (
              <BalanceTopUpCard
                key={creditPackage.id}
                creditPackage={creditPackage}
                onClick={handlePurchaseClick}
                isLoading={isPaymentCreateLoading}
                currency={selectedCurrency || savedCurrency}
              />
            ))}
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: StoreState) => ({
  isLoading:
    state.package.allPackagesLoading ||
    state.currency.userCurrencyISOCodeFromLocationLoading ||
    state.currency.stripeSupportedCurrenciesLoading,
  allPackages: state.package.allPackages,
  redirectUrl: state.payment.redirectUrl,
  isPaymentCreateLoading: state.payment.paymentCreateLoading,
  currentUser: state.user.currentUser,
  userCurrencyISOCodeFromLocation:
    state.currency.userCurrencyISOCodeFromLocation,
  stripeSupportedCurrencies: state.currency.stripeSupportedCurrencies,
  currencyRate: state.currency.currencyRate,
  isCurrencyRateLoading: state.currency.currencyRateLoading,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<any, any, AnyAction>) => ({
  onFetchAllPackages: () => dispatch(packageService.fetchAllPackages()),
  onResetCreatePayment: () => dispatch(resetCreatePayment()),
  onCreatePayment: (inputs: PaymentCreateRequest, intl: IntlShape) =>
    dispatch(paymentService.createPayment(inputs, intl)),
  onFetchStripeSupportedCurrencies: () =>
    dispatch(currencyService.fetchStripeSupportedCurrencies()),
  onFetchUserCurrencyISOCodeFromLocation: () =>
    dispatch(currencyService.fetchUserCurrencyISOCodeFromLocation()),
  onFetchCurrencyRate: (currencyCode: string) =>
    dispatch(currencyService.fetchCurrencyRate(currencyCode)),
});

export default connect(mapStateToProps, mapDispatchToProps)(BalanceTopUp);
