import * as actionTypes from './actionTypes';
import { UserRewardActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';
import { DailyRewardsStatus, UserReward } from '../../domain/UserReward';

export type UserRewardStateType = {
  createUserRewardLoading: boolean;
  createUserRewardSuccess: boolean;
  createUserRewardError: HttpError;
  createUserShareRewardLoading: boolean;
  createUserShareRewardSuccess: boolean;
  createUserShareRewardError: HttpError;
  dailyRewardsStatusLoading: boolean;
  dailyRewardsStatus: DailyRewardsStatus | null;
  dailyRewardsStatusError: HttpError;
  recentUserRewardsLoading: boolean;
  recentUserRewards: UserReward[];
  recentUserRewardsError: HttpError;
  userRewardsLoading: boolean;
  userRewards: UserReward[] | null;
  prevUserRewards: UserReward[] | null;
  userRewardsError: HttpError;
};

export type UserRewardActionType = UserRewardStateType & {
  type: UserRewardActionTypes;
};

export const initialState: UserRewardStateType = {
  createUserRewardLoading: false,
  createUserRewardSuccess: false,
  createUserRewardError: null,
  createUserShareRewardLoading: false,
  createUserShareRewardSuccess: false,
  createUserShareRewardError: null,
  dailyRewardsStatusLoading: false,
  dailyRewardsStatus: null,
  dailyRewardsStatusError: null,
  recentUserRewardsLoading: false,
  recentUserRewards: [],
  recentUserRewardsError: null,
  userRewardsLoading: false,
  userRewards: null,
  prevUserRewards: null,
  userRewardsError: null,
};

const createUserRewardStart = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  createUserRewardLoading: true,
});

const createUserRewardSuccess = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  createUserRewardLoading: false,
  createUserRewardSuccess: true,
  createUserRewardError: null,
});

const createUserRewardFail = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  createUserRewardLoading: false,
  createUserRewardError: action.createUserRewardError,
});

const fetchUserDailyRewardStart = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  dailyRewardsStatusLoading: true,
});

const fetchUserDailyRewardSuccess = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  dailyRewardsStatusLoading: false,
  dailyRewardsStatus: action.dailyRewardsStatus,
  dailyRewardsStatusError: null,
});

const fetchUserDailyRewardFail = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  dailyRewardsStatusLoading: false,
  dailyRewardsStatusError: action.dailyRewardsStatusError,
});

const createUserShareRewardStart = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  createUserShareRewardLoading: true,
});

const createUserShareRewardSuccess = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  createUserShareRewardLoading: false,
  createUserShareRewardSuccess: true,
  createUserShareRewardError: null,
});

const createUserShareRewardFail = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  createUserShareRewardLoading: false,
  createUserShareRewardError: action.createUserShareRewardError,
});

const fetchRecentUserRewardStart = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  recentUserRewardsLoading: true,
});

const fetchRecentUserRewardSuccess = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  recentUserRewardsLoading: false,
  recentUserRewards: action.recentUserRewards,
  recentUserRewardsError: null,
});

const fetchRecentUserRewardFail = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  recentUserRewardsLoading: false,
  recentUserRewardsError: action.recentUserRewardsError,
});

const fetchUserRewardsStart = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...state,
  userRewardsLoading: true,
});

const fetchUserRewardsSuccess = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => {
  if (state.userRewards) {
    return {
      ...state,
      userRewardsLoading: false,
      prevUserRewards: state.userRewards,
      userRewards: action.userRewards,
    };
  }

  return {
    ...state,
    userRewardsLoading: false,
    userRewards: action.userRewards,
  };
};

const fetchUserRewardsFail = (
  state: UserRewardStateType,
  action: UserRewardActionType,
): UserRewardStateType => ({
  ...state,
  userRewardsError: action.userRewardsError,
  userRewardsLoading: false,
});

const resetUserRewardStore = (
  state: UserRewardStateType,
): UserRewardStateType => ({
  ...initialState,
});

const completelyResetUserRewardStore = (): UserRewardStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: UserRewardActionType) => {
  switch (action.type) {
    case actionTypes.CREATE_USER_REWARD_START:
      return createUserRewardStart(state);
    case actionTypes.CREATE_USER_REWARD_SUCCESS:
      return createUserRewardSuccess(state);
    case actionTypes.CREATE_USER_REWARD_FAIL:
      return createUserRewardFail(state, action);
    case actionTypes.FETCH_USER_DAILY_REWARDS_STATUS_START:
      return fetchUserDailyRewardStart(state);
    case actionTypes.FETCH_USER_DAILY_REWARDS_STATUS_SUCCESS:
      return fetchUserDailyRewardSuccess(state, action);
    case actionTypes.FETCH_USER_DAILY_REWARDS_STATUS_FAIL:
      return fetchUserDailyRewardFail(state, action);
    case actionTypes.CREATE_USER_REWARD_START:
      return createUserShareRewardStart(state);
    case actionTypes.CREATE_USER_REWARD_SUCCESS:
      return createUserShareRewardSuccess(state);
    case actionTypes.CREATE_USER_REWARD_FAIL:
      return createUserShareRewardFail(state, action);
    case actionTypes.FETCH_USER_RECENT_REWARDS_START:
      return fetchRecentUserRewardStart(state);
    case actionTypes.FETCH_USER_RECENT_REWARDS_SUCCESS:
      return fetchRecentUserRewardSuccess(state, action);
    case actionTypes.FETCH_USER_RECENT_REWARDS_FAIL:
      return fetchRecentUserRewardFail(state, action);
    case actionTypes.FETCH_USER_REWARDS_START:
      return fetchUserRewardsStart(state);
    case actionTypes.FETCH_USER_REWARDS_SUCCESS:
      return fetchUserRewardsSuccess(state, action);
    case actionTypes.FETCH_USER_REWARDS_FAIL:
      return fetchUserRewardsFail(state, action);
    case actionTypes.RESET_USER_REWARD_STORE:
      return resetUserRewardStore(state);
    case actionTypes.LOGOUT:
      return completelyResetUserRewardStore();
    default:
      return state;
  }
};

export default reducer;
