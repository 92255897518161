import axios from '../../config/Axios/axios-instance';
import { Dispatch } from 'redux';
import {
  resetBricksStatusFail,
  resetBricksStatusStart,
  resetBricksStatusSuccess,
  updateLoginStatusFail,
  updateLoginStatusStart,
  updateLoginStatusSuccess,
} from './actions';

const API_URL = '/profile';

export const resetBricksStatus = () => (dispatch: Dispatch) => {
  dispatch(resetBricksStatusStart());
  return axios
    .patch(API_URL)
    .then(() => {
      dispatch(resetBricksStatusSuccess());
    })
    .catch((err) => {
      dispatch(resetBricksStatusFail(err?.response?.data?.message));
    });
};

export const updateLoginStatus = () => (dispatch: Dispatch) => {
  dispatch(updateLoginStatusStart());
  return axios
    .patch(`${API_URL}/login-status`)
    .then(() => {
      dispatch(updateLoginStatusSuccess());
    })
    .catch((err) => {
      dispatch(updateLoginStatusFail(err?.response?.data?.message));
    });
};
