import * as actionTypes from './actionTypes';
import { ProfileActionTypes } from './actionTypes';
import { HttpError } from '../../config/Axios/axios-instance';

export type ProfileStateType = {
  resetBricksStatusSuccess: boolean;
  resetBricksStatusLoading: boolean;
  resetBricksStatusError: HttpError;
  updateLoginStatusLoading: boolean;
  updateLoginStatusSuccess: boolean;
  updateLoginStatusError: HttpError;
};

export type ProfileActionType = ProfileStateType & {
  type: ProfileActionTypes;
};

export const initialState: ProfileStateType = {
  resetBricksStatusSuccess: false,
  resetBricksStatusLoading: false,
  resetBricksStatusError: null,
  updateLoginStatusLoading: false,
  updateLoginStatusSuccess: false,
  updateLoginStatusError: null,
};

const resetBricksStatusStart = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  resetBricksStatusLoading: true,
  resetBricksStatusSuccess: false,
});

const resetBricksStatusSuccess = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  resetBricksStatusLoading: false,
  resetBricksStatusSuccess: true,
});

const resetBricksStatusFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  resetBricksStatusLoading: false,
  resetBricksStatusError: action.resetBricksStatusError,
  resetBricksStatusSuccess: false,
});

const updateLoginStatusStart = (state: ProfileStateType): ProfileStateType => ({
  ...state,
  updateLoginStatusLoading: true,
  updateLoginStatusSuccess: false,
});

const updateLoginStatusSuccess = (
  state: ProfileStateType,
): ProfileStateType => ({
  ...state,
  updateLoginStatusLoading: false,
  updateLoginStatusSuccess: true,
});

const updateLoginStatusFail = (
  state: ProfileStateType,
  action: ProfileActionType,
): ProfileStateType => ({
  ...state,
  updateLoginStatusLoading: false,
  updateLoginStatusError: action.updateLoginStatusError,
  updateLoginStatusSuccess: false,
});

const resetWithdrawalStore = (): ProfileStateType => ({
  ...initialState,
});

const completelyResetWithdrawalStore = (): ProfileStateType => ({
  ...initialState,
});

const reducer = (state = initialState, action: ProfileActionType) => {
  switch (action.type) {
    case actionTypes.RESET_BRICKS_STATUS_START:
      return resetBricksStatusStart(state);
    case actionTypes.RESET_BRICKS_STATUS_SUCCESS:
      return resetBricksStatusSuccess(state);
    case actionTypes.RESET_BRICKS_STATUS_FAIL:
      return resetBricksStatusFail(state, action);
    case actionTypes.UPDATE_LOGIN_STATUS_START:
      return updateLoginStatusStart(state);
    case actionTypes.UPDATE_LOGIN_STATUS_SUCCESS:
      return updateLoginStatusSuccess(state);
    case actionTypes.UPDATE_LOGIN_STATUS_FAIL:
      return updateLoginStatusFail(state, action);
    case actionTypes.RESET_PROFILE_STORE:
      return resetWithdrawalStore();
    case actionTypes.LOGOUT:
      return completelyResetWithdrawalStore();
    default:
      return state;
  }
};

export default reducer;
