export const getBricksValueInEuro = (bricks: number) => {
  if (bricks < 0) {
    return 0;
  }

  const bricksToEurosRate = 1956;

  return bricks / bricksToEurosRate;
};

export const getEurValueInBricks = (eur: number) => {
  if (eur < 0) {
    return 0;
  }

  const bricksToEurosRate = 1956;

  return +(eur * bricksToEurosRate).toFixed();
};

export const getStudsValueInBricks = (studs: number) => {
  if (studs < 0) {
    return 0;
  }

  const studsToBricksRate = 27.28;

  const price = studs / studsToBricksRate;

  return price;
};

export const convertToKNotation = (value: number) => {
  if (value >= 1000000) {
    const kkValue = value / 1000000;

    return `${kkValue.toFixed(2)}M`;
  }

  if (value >= 10000) {
    const kValue = value / 1000;

    return `${kValue.toFixed(2)}K`;
  }

  return value.toFixed(2);
};

export const convertPartsToKNotation = (value: number) => {
  if (value >= 1000) {
    const kValue = value / 1000;
    return `${kValue.toFixed(1)}K`;
  }

  return value.toFixed(0);
};
