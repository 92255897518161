export const FETCH_DISCORD_AUTH_LINK_START = 'FETCH_DISCORD_AUTH_LINK_START';
export const FETCH_DISCORD_AUTH_LINK_SUCCESS =
  'FETCH_DISCORD_AUTH_LINK_SUCCESS';
export const FETCH_DISCORD_AUTH_LINK_FAIL = 'FETCH_DISCORD_AUTH_LINK_FAIL';

export const FETCH_DISCORD_CONNECTION_START = 'FETCH_DISCORD_CONNECTION_START';
export const FETCH_DISCORD_CONNECTION_SUCCESS =
  'FETCH_DISCORD_CONNECTION_SUCCESS';
export const FETCH_DISCORD_CONNECTION_FAIL = 'FETCH_DISCORD_CONNECTION_FAIL';

export const RESET_DISCORD_STORE = 'RESET_DISCORD_STORE';

export type DiscordActionTypes =
  | typeof FETCH_DISCORD_AUTH_LINK_START
  | typeof FETCH_DISCORD_AUTH_LINK_SUCCESS
  | typeof FETCH_DISCORD_AUTH_LINK_FAIL
  | typeof FETCH_DISCORD_CONNECTION_START
  | typeof FETCH_DISCORD_CONNECTION_SUCCESS
  | typeof FETCH_DISCORD_CONNECTION_FAIL
  | typeof RESET_DISCORD_STORE;
