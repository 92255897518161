export const FETCH_PRODUCT_TAG_SUGGESTIONS_START =
  'FETCH_PRODUCT_TAG_SUGGESTIONS_START';
export const FETCH_PRODUCT_TAG_SUGGESTIONS_SUCCESS =
  'FETCH_PRODUCT_TAG_SUGGESTIONS_SUCCESS';
export const FETCH_PRODUCT_TAG_SUGGESTIONS_FAIL =
  'FETCH_PRODUCT_TAG_SUGGESTIONS_FAIL';

export const RESET_PRODUCT_TAG_SUGGESTIONS = 'RESET_PRODUCT_TAG_SUGGESTIONS';

export type TagActionTypes =
  | typeof FETCH_PRODUCT_TAG_SUGGESTIONS_START
  | typeof FETCH_PRODUCT_TAG_SUGGESTIONS_SUCCESS
  | typeof FETCH_PRODUCT_TAG_SUGGESTIONS_FAIL
  | typeof RESET_PRODUCT_TAG_SUGGESTIONS;
